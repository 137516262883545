import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { EditInDialogButton } from "@react-admin/ra-form-layout";
import * as React from "react";
import { AppBar, Logout, UserMenu } from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings";
import { useRedirect } from "react-admin";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { hasRole, LoginRoleTypes } from "components/forms/common/permissions";
import { UserProfileForm } from "components/getForms/GetForms";
import { Link } from "react-router-dom";
import logo1 from "../../assets/images/logo-ibe-1.png";
import logo from "../../assets/images/logo-ibe-1.png";

const RedirectToSettings = () => {
  let isSuper = hasRole(LoginRoleTypes.SUPER);
  let isBusinessAdministrator = hasRole(LoginRoleTypes.BUSINESS_ADMINISTRATOR);
  const redirect = useRedirect();
  const handleClick = () => {
    redirect("/appsettings");
  };
  if (isSuper || isBusinessAdministrator) {
    return (
      <IconButton onClick={handleClick} aria-label="settings">
        <SettingsIcon />
      </IconButton>
    );
  } else {
    return null;
  }
};

const getLogo = (IBE: string) => {
  switch (IBE) {
    case "1":
      return logo1;
    default:
      return logo;
  }
};

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  logo: {
    height: "50px",
  },
  spacer: {
    flex: 1,
  },
});

const AppSettingsMenu = React.forwardRef((props, ref) => {
  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      ref={ref}
      {...props}
      to="/appsettings"
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>App Settings</ListItemText>
    </MenuItem>
  );
});

const MyProfileMenu = React.forwardRef((props, ref) => {
  let thisPersonId = 0;
  thisPersonId = PersonId;

  let toURL = "/userprofiles/" + thisPersonId;

  return (
    <MenuItem>
      <ListItemText>
        <EditInDialogButton
          className={"no-dialog-styling"}
          icon={<ManageAccountsIcon />}
          id={thisPersonId}
          resource="userprofiles"
          label="User Profile"
          fullWidth
          maxWidth="md"
          sx={{ ".MuiPaper-root": { border: "0px" } }}
          title=" "
        >
          <UserProfileForm />
        </EditInDialogButton>
      </ListItemText>
    </MenuItem>
  );
});

const MyUserMenu = (props: any) => {
  const isSuper = hasRole(LoginRoleTypes.SUPER);
  const isBusinessAdministrator = hasRole(
    LoginRoleTypes.BUSINESS_ADMINISTRATOR
  );

  return (
    <UserMenu {...props}>
      <MyProfileMenu />
      {(isSuper || isBusinessAdministrator) && <AppSettingsMenu />}
      <Logout />
    </UserMenu>
  );
};

const CustomAppBar = (props: any) => {
  const classes = useStyles();

  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar {...props} elevation={1} userMenu={<MyUserMenu />} color="primary">
      {!isXSmall && (
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
      )}
      <span className={classes.spacer} />
      <Toolbar variant="dense">
        <img src={getLogo("1")} className={classes.logo} alt="logo" />
      </Toolbar>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
