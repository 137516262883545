import {
  CreateDialogComponent,
  EditDialogComponent,
} from "components/forms/dialogs/ModalDialog";
import {
  Datagrid,
  List as RAList,
  ReferenceField,
  TextField,
} from "react-admin";
import { AppSettingsForm } from "../../getForms/GetForms";
import { hasRole, LoginRoleTypes } from "../common/permissions";
import { IBEListAside } from "../common/utilities";
import { AppSettingFilters } from "../../getForms/ListFilters";

export const AppSettingList = () => {
  let isSuper = hasRole(LoginRoleTypes.SUPER);
  let isTrader = hasRole(LoginRoleTypes.TRADER);
  //let isTrader = false;

  return (
    <div>
      <RAList
        resource="appsettings"
        title="App Settings"
        filters={AppSettingFilters}
        hasCreate={isSuper}
        perPage={25}
        {...(isSuper
          ? {}
          : {
              ...(isTrader
                ? {
                    filter: {
                      LocalUserID: PersonId,
                    },
                  }
                : {}),
            })}
      >
        <Datagrid optimized rowClick="edit">
          <TextField source="Key" />
          <TextField source="ValueType" label="Type" />
          <TextField source="Value" />
          {isSuper && (
            <ReferenceField
              label="User"
              source="LocalUserID"
              reference="localusers"
            >
              <TextField source="DisplayName" />
            </ReferenceField>
          )}
        </Datagrid>
      </RAList>

      <EditDialogComponent maxWidth="sm" form={AppSettingsForm} />
      <CreateDialogComponent form={AppSettingsForm} />
    </div>
  );
};
