export enum InventoryTransactionActions {
  RECEIVE = 1,
  TRANSFER = 2,
  CONSUME = 3,
  RETURN = 4,
  SURPLUS = 5,
  MARK_AS_LOST = 6,
  ACCEPT_INVENTORY_TRANSFER = 7,
  DECLINE_INVENTORY_TRANSFER = 8,
  CANCEL_INVENTORY_TRANSFER = 9,
  PENDING_INVENTORY_TRANSFER = 10,
}

export const InventoryTransactionActionChoices = [
  { id: "1", name: "RECEIVE" },
  { id: "2", name: "TRANSFER" },
  { id: "3", name: "CONSUME" },
  { id: "4", name: "RETURN" },
  { id: "5", name: "SURPLUS" },
  { id: "6", name: "MARK_AS_LOST" },
  { id: "7", name: "ACCEPT_INVENTORY_TRANSFER" },
  { id: "8", name: "DECLINE_INVENTORY_TRANSFER" },
  { id: "9", name: "CANCEL_INVENTORY_TRANSFER" },
  { id: "10", name: "PENDING_INVENTORY_TRANSFER" },
];

export enum InventoryTransactionModelIds {
  BinLocation = 1,
  People = 2, //only one that should implement pending for transfer
  Asset = 3,
  SalesOrder = 4,
  WorkOrder = 5,
  SalesOrderItem = 6,
  WorkOrderItem = 7,
}

export const InventoryTransactionModelChoices = [
  { id: "1", name: "Warehouse Bin" },
  { id: "2", name: "Field Technician" },
  { id: "3", name: "Asset" },
  { id: "4", name: "Sales Order" },
  { id: "5", name: "Work Order" },
  { id: "6", name: "Sales Order Item" },
  { id: "7", name: "Work Order Item" },
];

export enum TransferInventoryFormType {
  ToPersonWarehouse = 1,
  ToWorkOrder = 2,
  FromWorkOrder = 3,
  FieldTechToFieldTech = 4,
}

export const InventoryTransactionModelIdChoices = [
  { id: "1", name: "Bin Location" },
  { id: "2", name: "Field Technician" },
];

export const InventoryTransactionModelIdFieldTechnicianChoices = [
  { id: "2", name: "Field Technician" },
];

export const InventoryTransactionModelIdBinLocationChoices = [
  { id: "1", name: "Bin Location" },
];

export const InventoryTransactionModelIdWorkOrderChoices = [
  { id: "5", name: "Work Order" },
];

export enum WorkOrderStatusTypes {
  NOT_SCHEDULED = 1,
  SCHEDULED = 2,
  IN_ROUTE = 3,
  ON_SITE = 4,
  IN_PROGRESS = 5,
  ON_HOLD = 6,
  CANCELLED = 7,
  COMPLETED = 8,
  PENDING_COMPLETION = 9,
  RESCHEDULED = 10,
  RESCHEDULED_PENDING_REVIEW = 11,
  CANCELED_PENDING_REVIEW = 12,
  ON_HOLD_PENDING_REVIEW = 13,
}

export enum WorkOrderTaskStatusTypes {
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  PAUSED = 3,
  COMPLETED = 5,
}

export interface InterfaceTimeSlot {
  id: string;
  name: string;
}

export const TimeSlots = [
  { id: "08:00", name: "8-10 AM" },
  { id: "10:00", name: "10-Noon" },
  { id: "12:00", name: "Noon-2 PM" },
  { id: "14:00", name: "2-4 PM" },
  { id: "16:00", name: "4-6 PM" },
];

export const TrendFilterChoices = [
  { id: "open", name: "Open Trading Hours" },
  { id: "all", name: "All Hours" },
];

export const TrendIntervalChoices = [
  { id: "1min", name: "1 Minute" },
  { id: "5min", name: "5 Minutes" },
  { id: "15min", name: "15 Minutes" },
];
