import TextField from "@mui/material/TextField";
import { Button, Title } from "react-admin";

import { useState } from "react";

import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import Box from "@mui/material/Box";
import apiConfig from "config/apiconfig.json";
export const TradierAPI = () => {
  const [apiResult, SetAPIResult] = useState("");

  const CallTradierAPI = (props: any) => {
    SetAPIResult("");
    sendTradierAction();
  };

  const ClearTradierResult = (props: any) => {
    SetAPIResult("");
  };

  const sendTradierAction = async () => {
    let newData: any;
    let url: any = document.getElementById("apiURL") as HTMLElement;

    let fullURL = apiConfig.api_url + "/tradierapicall?url=" + url.value;

    const headers = { Authorization: apiConfig.api_value };
    const response = await fetch(fullURL, { headers });

    newData = await response.json();
    if (newData == null) {
      SetAPIResult("");
    }
    if (response.status !== 200) {
      SetAPIResult("");
      return [false, newData];
    }

    SetAPIResult(newData);
  };

  return (
    <div>
      <Title title="Tradier API Calls" />

      <h2>Tradier API Callback</h2>
      <h4>ALL example - 1 Minute</h4>
      <p>
        https://api.tradier.com/v1/markets/timesales?symbol=AAPL&interval=1min&start=2024-07-08
        05:30&end=2024-07-08 16:00&session_filter=all
      </p>
      <h4>OPEN example - 15 Minute</h4>
      <p>
        https://api.tradier.com/v1/markets/timesales?symbol=AAPL&interval=15min&start=2024-07-08
        09:30&end=2024-07-08 11:00&session_filter=open
      </p>
      <h4>
        JSON to CSV converter (
        <a target="_blank" href="https://konklone.io/json/">
          https://konklone.io/json/
        </a>
        )
      </h4>

      <Box
        sx={{
          width: "80%",
          margin: "0px",
          maxWidth: "80%",
          padding: "10px 10px",
        }}
      >
        <TextField
          required
          id="apiURL"
          label="API URL"
          multiline
          fullWidth
          rows={2}
          variant="filled"
        />
      </Box>
      <Button
        sx={{ margin: "1em" }}
        size="medium"
        variant="contained"
        label="Send"
        onClick={CallTradierAPI}
      >
        <CompareArrowsIcon />
      </Button>
      <Button
        sx={{ margin: "1em" }}
        size="medium"
        variant="contained"
        label="Clear Results"
        onClick={ClearTradierResult}
      >
        <RestartAltIcon />
      </Button>
      <Box
        sx={{
          width: "80%",
          margin: "0px",
          maxWidth: "80%",
          padding: "10px 10px",
        }}
      >
        <TextField
          id="apiResult"
          label="API Result"
          value={apiResult}
          multiline
          fullWidth
          rows={10}
          variant="filled"
        />
      </Box>
    </div>
  );
};
