import { makeStyles } from "@material-ui/core/styles";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {
  EmailField,
  List as RAList,
  ListButton,
  required,
  TextField,
  TopToolbar,
} from "react-admin";
import { IBEPeopleListAside } from "../common/utilities";

import {
  CreateDialogComponent,
  EditDialogComponent,
} from "components/forms/dialogs/ModalDialog";
import {
  DisabledField,
  EmptyDataForm,
  ListActions,
  MyDatagridConfigurable,
} from "components/getForms/FormUtilities";
import { PeopleFilters } from "components/getForms/ListFilters";
import { PeopleForm } from "../../getForms/GetForms";
import { hasRole, LoginRoleTypes } from "../common/permissions";
//  let isSuper = hasRole(LoginRoleTypes.SUPER);
export const PeopleList = (props: any) => {
  let isSuper = hasRole(LoginRoleTypes.SUPER);

  // ## Normal VIEW ## //
  if (!isSuper) {
    return (
      <div>
        <RAList
          empty={<EmptyDataForm title="Employees" />}
          hasCreate={true}
          title="Employees"
          actions={
            <ListActions
              createButtonLabel="New Employee"
              showFilter={true}
              showCreate={true}
              showExport={true}
            />
          }
          filters={PeopleFilters}
          sort={{ field: "name", order: "ASC" }}
          perPage={25}
          filter={{
            entityType: "InternalBusinessEntity",
            entityId: InternalBusinessEntityId,
          }}
        >
          <MyDatagridConfigurable rowClick="edit" omit={["disabled"]}>
            <TextField source="name" />
            <EmailField source="phone" />
            <TextField source="email" />
            <TextField source="userName" />
            <DisabledField source="disabled" fieldType="BooleanField" label="Disabled?" />
          </MyDatagridConfigurable>
        </RAList>

        <EditDialogComponent form={PeopleForm} />
        <CreateDialogComponent form={PeopleForm} />
      </div>
    );
  }

  // ## SUPER VIEW ## //
  else {
    return (
      <div>
        <RAList
          hasCreate={true}
          empty={<EmptyDataForm title="Employee" />}
          title="Employees"
          actions={
            <ListActions
              createButtonLabel="New Employee"
              showFilter={true}
              showCreate={true}
              showExport={true}
            />
          }
          filters={PeopleFilters}
          sort={{ field: "name", order: "ASC" }}
          perPage={25}
          aside={<IBEPeopleListAside />}
          filter={{
            entityType: "InternalBusinessEntity",
          }}
        >
          <MyDatagridConfigurable rowClick="edit" omit={["disabled"]}>
            <TextField source="name" />
            <EmailField source="phone" />
            <TextField source="email" />
            <TextField source="userName" />
            <TextField label="Company" source="Entity.name" />
            <DisabledField source="disabled" fieldType="BooleanField" label="Disabled?" />
          </MyDatagridConfigurable>
        </RAList>

        <EditDialogComponent form={PeopleForm} />
        <CreateDialogComponent form={PeopleForm} />
      </div>
    );
  }
};