import DescriptionIcon from '@material-ui/icons/Description';

import { LookUpTypeList } from './LookUpType';

const resource = {
    list: LookUpTypeList,
    icon: DescriptionIcon,
};

export default resource;
