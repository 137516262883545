import Box from "@mui/material/Box";
import { useState } from "react";

import ShowChartIcon from "@mui/icons-material/ShowChart";
import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
} from "react-admin";

import { HorizontalMenu } from "@react-admin/ra-navigation";
import { AllMenuItems } from "components/forms/common/permissions";
import apiConfig from "config/apiconfig.json";
import people from "../forms/people";
import SubMenu from "./SubMenu";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { hasMenu } from "components/forms/common/permissions";

type MenuName =
  | "menuIBE"
  | "menuAddress"
  | "menuStockWatch"
  | "menuAdministration"
  | "menuRole"
  | "menuProject"
  | "menuSupplier"
  | "menuPeople"
  | "menuInventory"
  | "menuTransaction"
  | "menuPermissions"
  | "menuReports";

export const Menu = ({ dense = false }: MenuProps) => {
  let LocalUserID = PersonId;
  const [TradeStationRedirectURL, SetTradeStationRedirectURL] = useState("");
  const [isFirstLoad, SetIsFirstLoad] = useState(true);

  const [state, setState] = useState({
    menuIBE: false,
    menuAddress: false,
    menuStockWatch: false,
    menuAdministration: false,
    menuRole: false,
    menuProject: false,
    menuSupplier: false,
    menuPeople: false,
    menuInventory: false,
    menuTransaction: false,
    menuPermissions: false,
    menuReports: false,
  });
  const [open] = useSidebarState();

  const MySubMenu = (props: any) => {
    if (hasMenu(props.menuId)) {
      return <SubMenu {...props}>{props.children}</SubMenu>;
    } else {
      return null;
    }
  };
  const MyMenuItemLink = (props: any) => {
    if (hasMenu(props.menuId)) {
      return <MenuItemLink {...props} />;
    } else {
      return null;
    }
  };
  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const GetTradeStationRedirectURL = async () => {
    // Define API and server constants
    //Need to get clientID from database on login
    //and database for redirect URL
    const TRADINGPLATFORM_KEY = "TRADESTATION";
    let API_AUTHORIZE_URL: any;
    let API_RESPONSETYPE: any;
    let REDIRECT_URI: any;
    let API_AUDIENCE: any;
    let API_SCOPE: any;

    let API_CLIENTID: any;
    let TradingPlatformID: any;
    let redirectUri: string;
    redirectUri = "";

    const headers = { Authorization: apiConfig.api_value };
    const res = await fetch(
      apiConfig.api_url +
        "/usertradingplatformsetup?LocalUserID=" +
        PersonId +
        "&Key=" +
        TRADINGPLATFORM_KEY,
      { headers }
    );
    const data = await res.json();
    API_AUTHORIZE_URL = data.AuthorizeUrl;
    API_RESPONSETYPE = data.ResponseType;
    REDIRECT_URI = data.RedirectUri;
    API_AUDIENCE = data.APIAudience;
    API_SCOPE = data.ApiScope;
    API_CLIENTID = data.AccessKey;
    TradingPlatformID = data.id;

    redirectUri =
      API_AUTHORIZE_URL +
      "?response_type=" +
      API_RESPONSETYPE +
      "&client_id=" +
      API_CLIENTID +
      "&redirect_uri=" +
      REDIRECT_URI +
      "&audience=" +
      API_AUDIENCE +
      "&state=" +
      LocalUserID +
      "&scope=" +
      API_SCOPE;

    SetTradeStationRedirectURL(redirectUri);
  };

  if (isFirstLoad) {
    GetTradeStationRedirectURL();
    SetIsFirstLoad(false);
  }

  return (
    <Box
      sx={{
        width: open ? 200 : 40,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />

      <MyMenuItemLink
        menuId={AllMenuItems.WeeklyStockWatches}
        to="/weeklystockwatches"
        state={{ _scrollToTop: true }}
        primaryText="Stock Watches"
        leftIcon={<people.icon />}
        dense={dense}
      />
      <MyMenuItemLink
        menuId={AllMenuItems.WeeklyStockWatches}
        to={TradeStationRedirectURL}
        state={{ _scrollToTop: true }}
        primaryText="TradeStation"
        leftIcon={<people.icon />}
        dense={dense}
        target="_blank"
      />
      <MyMenuItemLink
        menuId={AllMenuItems.WeeklyStockWatches}
        to="/tradierapi"
        state={{ _scrollToTop: true }}
        primaryText="Tradier API"
        leftIcon={<ShowChartIcon />}
        dense={dense}
      />
      <MySubMenu
        menuId={AllMenuItems.Administration}
        handleToggle={() => handleToggle("menuAdministration")}
        isOpen={state.menuAdministration}
        name="Data Administration"
        icon={<ExpandMoreIcon />}
        dense={dense}
      >
        <MyMenuItemLink
          menuId={AllMenuItems.Roles}
          to="/roles"
          state={{ _scrollToTop: true }}
          primaryText="Roles"
          leftIcon={<people.icon />}
          dense={dense}
        />
        <MyMenuItemLink
          menuId={AllMenuItems.LocalUsers}
          to="/localusers"
          state={{ _scrollToTop: true }}
          primaryText="Users"
          leftIcon={<people.icon />}
          dense={dense}
        />
        <MyMenuItemLink
          menuId={AllMenuItems.TradingPlatforms}
          to="/tradingplatforms"
          state={{ _scrollToTop: true }}
          primaryText="Trading Platforms"
          leftIcon={<people.icon />}
          dense={dense}
        />
      </MySubMenu>
    </Box>
  );
};

export const MyHorizontalMenu = ({ dense = false }: MenuProps) => {
  return (
    <HorizontalMenu>
      <HorizontalMenu.Item label="Dashboard" to="/" value="" />
      <HorizontalMenu.Item
        label="Current Prices"
        to="/currentstockprices"
        value="Current Prices"
      />
      <HorizontalMenu.Item
        label="Trade Platforms"
        to="/tradingplatforms"
        value="Trade Platforms"
      />
      <HorizontalMenu.Item
        label="Stock Watches"
        to="/weeklystockwatches"
        value="Stock Watches"
      />
    </HorizontalMenu>
  );
};
