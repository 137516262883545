import {
  CreateDialogComponent,
  EditDialogComponent,
} from "components/forms/dialogs/ModalDialog";
import { EmptyDataForm, ListActions } from "components/getForms/FormUtilities";
import { BooleanField, List as RAList, TextField } from "react-admin";
import { LocalUserForm } from "../../getForms/GetForms";

import { MyDatagridConfigurable } from "components/getForms/FormUtilities";

export const LocalUserList = (props: any) => {
  let i = 0;
  i = i + 1;
  return (
    <div>
      <RAList
        empty={<EmptyDataForm title="Users" />}
        resource="localusers"
        title="Users"
        actions={
          <ListActions
            createButtonLabel="New User"
            showFilter={false}
            showCreate={true}
            showExport={true}
          />
        }
        sort={{ field: "DisplayName", order: "ASC" }}
        perPage={25}
      >
        <MyDatagridConfigurable rowClick="edit" omit={["disabled"]}>
          <TextField source="DisplayName" />
          <TextField source="UserName" />
        </MyDatagridConfigurable>
      </RAList>

      <CreateDialogComponent maxWidth={false} form={LocalUserForm} />
      <EditDialogComponent maxWidth={false} form={LocalUserForm} />
    </div>
  );
};
