import DescriptionIcon from "@material-ui/icons/Description";

import { LocalUserList } from "./LocalUser";

const resource = {
  list: LocalUserList,
  icon: DescriptionIcon,
};

export default resource;
