import * as React from "react";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  ExportButton,
  CreateButton,
  Edit,
  Create,
  Form,
  Toolbar,
  SimpleForm,
  TextInput,
  EditProps,
  ListButton,
} from "react-admin";

import { CardContent, Stack, Avatar, Box } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";

import { LookUpTypeForm } from "../../getForms/GetForms";

import EditDialogComponent from "../../dialogs/EditDialog";
import { CreateDialog } from "@react-admin/ra-form-layout";
import ShowData from "../../showData/ShowData"

export const LookUpTypeList = () => (
    <div>
      <List
        actions={<LookupListActions />}
        sort={{ field: "name", order: "ASC" }}
        perPage={25}
      >
        <Datagrid optimized rowClick="edit">
          <TextField source="name" />
        </Datagrid>
      </List>
      <EditDialogComponent form={LookUpTypeForm} />
      <CreateDialog fullWidth maxWidth="md">
        <LookUpTypeForm />
      </CreateDialog>


    </div>
    );

export const LookupListActions = () => {
  return (
    <TopToolbar>
      <CreateButton
        variant="contained"
        label="New"
        sx={{ marginLeft: 2 }}
      />
      <ExportButton />
    </TopToolbar>
  );
};
