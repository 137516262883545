import {
  CreateDialogComponent,
  EditDialogComponent,
} from "components/forms/dialogs/ModalDialog";
import { EmptyDataForm, ListActions } from "components/getForms/FormUtilities";
import { List as RAList, TextField } from "react-admin";
import { TradingPlatformForm } from "../../getForms/GetForms";

import { MyDatagridConfigurable } from "components/getForms/FormUtilities";

export const TradingPlatformList = (props: any) => {
  return (
    <div>
      <RAList
        empty={<EmptyDataForm title="Trading Platforms" />}
        resource="tradingplatforms"
        title="Trading Platforms"
        actions={
          <ListActions
            createButtonLabel="New Trading Platform"
            showFilter={false}
            showCreate={true}
            showExport={true}
          />
        }
        sort={{ field: "name", order: "ASC" }}
        perPage={25}
      >
        <MyDatagridConfigurable rowClick="edit" omit={["disabled"]}>
          <TextField source="name" />
          <TextField source="key" />
          <TextField source="apiUrl" />
          <TextField source="authTokenUrl" />
          <TextField source="description" />
        </MyDatagridConfigurable>
      </RAList>

      <CreateDialogComponent maxWidth={false} form={TradingPlatformForm} />
      <EditDialogComponent maxWidth={false} form={TradingPlatformForm} />
    </div>
  );
};
