import {
  BooleanInput,
  DateInput,
  TextInput,
  NullableBooleanInput,
} from "react-admin";

import { IncludeDisabled } from "components/forms/common/utilities";
import { MyAutoCompleteInput, MyReferenceInput } from "./FormUtilities";

export const AppSettingFilters = [
  <TextInput source="Key" />,
  <MyReferenceInput
    alwaysOn
    label={"User"}
    source="LocalUserID"
    reference="localusers"
  >
    <MyAutoCompleteInput
      optionText="DisplayName"
      label="User"
      validate={false}
    />
  </MyReferenceInput>,
];

export const WeeklyStockWatchFilters = [
  <TextInput source="Symbol" alwaysOn resettable />,
  <NullableBooleanInput
    alwaysOn
    source="PaperTradeOnly"
    label="Paper Trade"
    nullLabel="Both"
    falseLabel="Live Trades ONLY"
    trueLabel="Paper Trades ONLY"
    fullWidth
    sx={{
      minWidth: "200px",
    }}
  />,
];

export const StockTransactionHistoryFiltersOLD = [
  <DateInput alwaysOn source="CreatedAt_gte" label="Dates Greater Than" />,
];

export const StockTransactionHistoryFilters = (todayString: any) => {
  //returns an array from function, rather than as const variable used elsewhere in code
  return [
    <DateInput
      value={todayString}
      alwaysOn
      source="CreatedAt_gte"
      label="Dates Greater Than"
    />,
    <DateInput
      value={todayString}
      alwaysOn
      source="CreatedAt_lte"
      label="Dates Less Than"
    />,
  ];
};

export const CustomerContactFilters = [
  <IncludeDisabled alwaysOn />,
  <TextInput source="name" />,
];

export const ContractItemFilters = [
  <IncludeDisabled alwaysOn />,
  <TextInput source="name" />,
];

export const PartnerFilters = [
  <IncludeDisabled alwaysOn />,
  <TextInput source="name" />,
  <MyReferenceInput
    label={"Subcontractor Type"}
    source="partnerTypeId"
    reference="partnertypes"
  >
    <MyAutoCompleteInput label="Subcontractor Type" validate={false} />
  </MyReferenceInput>,
];

export const WarehouseFilters = [
  <IncludeDisabled alwaysOn />,
  <TextInput source="name" />,
];

export const ContractFilters = (
  InternalBusinessEntityId: any,
  isSuper: boolean
) => {
  //returns an array from function, rather than as const variable used elsewhere in code
  return [
    <IncludeDisabled alwaysOn />,
    <TextInput source="name" />,
    <MyReferenceInput
      source="customerId"
      reference="customers"
      {...(!isSuper
        ? {
            filter: {
              internalBusinessEntityId: InternalBusinessEntityId,
              q: "parent",
            },
          }
        : { filter: { q: "parent" } })}
    >
      <MyAutoCompleteInput label="Customer" validate={false} />
    </MyReferenceInput>,
  ];
};

export const ProjectFilters = (
  InternalBusinessEntityId: any,
  isSuper: boolean
) => {
  //returns an array from function, rather than as const variable used elsewhere in code
  return [
    <IncludeDisabled alwaysOn />,
    <TextInput source="name" />,
    <MyReferenceInput
      label="Project Type"
      source="projectTypeId"
      reference="projecttypes"
    >
      <MyAutoCompleteInput label="Project Type" validate={false} />
    </MyReferenceInput>,
    <MyReferenceInput
      source="customerId"
      reference="customers"
      {...(!isSuper
        ? {
            filter: {
              internalBusinessEntityId: InternalBusinessEntityId,
              q: "parent",
            },
          }
        : { filter: { q: "parent" } })}
    >
      <MyAutoCompleteInput label="Customer" validate={false} />
    </MyReferenceInput>,
    <MyReferenceInput
      label="Project Manager"
      {...(!isSuper
        ? {
            filter: {
              internalBusinessEntityId: InternalBusinessEntityId,
              entityType: "Customer",
            },
          }
        : {
            filter: {
              entityType: "Customer",
            },
          })}
      source="personId"
      reference="people"
      referenceSourceField="customerId"
      dependencyFilterField="entityId"
    >
      <MyAutoCompleteInput label="Project Manager" validate={false} />
    </MyReferenceInput>,
  ];
};

export const SalesEstimateFilters = (
  InternalBusinessEntityId: any,
  isSuper: boolean
) => {
  //returns an array from function, rather than as const variable used elsewhere in code
  return [
    <TextInput source="salesOrderNumber" label="Sales Estimate Number" />,
    <TextInput source="customerPurchaseOrder" />,
    <TextInput source="customerServiceOrder" />,
    <MyReferenceInput
      source="customerId"
      reference="customers"
      {...(!isSuper
        ? {
            filter: {
              internalBusinessEntityId: InternalBusinessEntityId,
              q: "parent",
            },
          }
        : { filter: { q: "parent" } })}
    >
      <MyAutoCompleteInput label="Customer" validate={false} />
    </MyReferenceInput>,
    <MyReferenceInput
      label="Project"
      source="projectId"
      reference="projects"
      {...(!isSuper
        ? {
            filter: {
              internalBusinessEntityId: InternalBusinessEntityId,
            },
          }
        : {})}
    >
      <MyAutoCompleteInput label="Project" validate={false} />
    </MyReferenceInput>,
    <MyReferenceInput
      label="Type"
      source="salesOrderTypeId"
      reference="salesordertypes"
    >
      <MyAutoCompleteInput label="Estimate Type" validate={false} />
    </MyReferenceInput>,
    <MyReferenceInput
      label="Status"
      source="salesOrderStatusTypeId"
      reference="salesorderstatustypes"
    >
      <MyAutoCompleteInput label="Estimate Status" validate={false} />
    </MyReferenceInput>,
  ];
};

export const SalesOrderFilters = (
  InternalBusinessEntityId: any,
  isSuper: boolean
) => {
  //returns an array from function, rather than as const variable used elsewhere in code
  return [
    <MyReferenceInput
      alwaysOn
      source="customerId"
      reference="customers"
      {...(!isSuper
        ? {
            filter: {
              internalBusinessEntityId: InternalBusinessEntityId,
              q: "parent",
            },
          }
        : { filter: { q: "parent" } })}
    >
      <MyAutoCompleteInput label="Customer" validate={false} />
    </MyReferenceInput>,
    <TextInput source="salesOrderNumber" label="Sales Order Number" />,
    <TextInput source="customerPurchaseOrder" />,
    <TextInput source="customerServiceOrder" />,
    <MyReferenceInput
      label="Project"
      source="projectId"
      reference="projects"
      {...(!isSuper
        ? {
            filter: {
              internalBusinessEntityId: InternalBusinessEntityId,
            },
          }
        : {})}
    >
      <MyAutoCompleteInput label="Project" validate={false} />
    </MyReferenceInput>,
    <MyReferenceInput
      label="Type"
      source="salesOrderTypeId"
      reference="salesordertypes"
    >
      <MyAutoCompleteInput label="Order Type" validate={false} />
    </MyReferenceInput>,
    <MyReferenceInput
      label="Status"
      source="salesOrderStatusTypeId"
      reference="salesorderstatustypes"
    >
      <MyAutoCompleteInput label="Order Status" validate={false} />
    </MyReferenceInput>,
  ];
};

export const MasterItemFilters = (
  InternalBusinessEntityId: any,
  isSuper: boolean
) => {
  //returns an array from function, rather than as const variable used elsewhere in code
  return [
    <IncludeDisabled alwaysOn />,
    <TextInput source="name" />,
    <MyReferenceInput
      label="Manufacturer"
      source="manufacturerId"
      reference="ibemanufacturers"
      {...(!isSuper
        ? { filter: { internalBusinessEntityId: InternalBusinessEntityId } }
        : {})}
    >
      <MyAutoCompleteInput label="Manufacturer" validate={false} />
    </MyReferenceInput>,
    <MyReferenceInput
      label="Item Category"
      source="itemCategoryId"
      reference="itemcategories"
      {...(!isSuper
        ? {
            filter: {
              internalBusinessEntityId: InternalBusinessEntityId,
              q: "parent",
            },
          }
        : { filter: { q: "parent" } })}
    >
      <MyAutoCompleteInput label="Item Category" validate={false} />
    </MyReferenceInput>,
    <MyReferenceInput label="Unit" source="unitId" reference="units">
      <MyAutoCompleteInput label="Unit" validate={false} />
    </MyReferenceInput>,
  ];
};

export const ManufacturerFilters = [
  <IncludeDisabled alwaysOn />,
  <TextInput source="name" />,
];

export const MarketFilters = [
  <IncludeDisabled alwaysOn />,
  <TextInput source="name" />,
];

export const ItemCategoryFilters = [
  <IncludeDisabled alwaysOn q="parent" />,
  <TextInput source="name" />,
];

export const ItemSubCategoryFilters = (
  InternalBusinessEntityId: any,
  isSuper: boolean
) => {
  //returns an array from function, rather than as const variable used elsewhere in code
  return [
    <IncludeDisabled alwaysOn q="parent" />,
    <TextInput source="name" />,
    <MyReferenceInput
      link={false}
      label="Parent"
      source="parentCategoryId"
      reference="itemcategories"
      {...(!isSuper
        ? {
            filter: {
              internalBusinessEntityId: InternalBusinessEntityId,
              q: "parent",
            },
          }
        : { filter: { q: "parent" } })}
      perPage={1000}
    >
      <MyAutoCompleteInput label="Parent Category" validate={false} />
    </MyReferenceInput>,
  ];
};

export const WorkOrderFilters = (
  InternalBusinessEntityId: any,
  isSuper: boolean
) => {
  //returns an array from function, rather than as const variable used elsewhere in code
  return [
    <BooleanInput alwaysOn source="isOpen" label="Is Open?" />,
    <MyReferenceInput
      alwaysOn
      label="Customer"
      source="customerId"
      reference="customers"
      {...(!isSuper
        ? {
            filter: {
              internalBusinessEntityId: InternalBusinessEntityId,
              q: "parent",
            },
          }
        : { filter: { q: "parent" } })}
    >
      <MyAutoCompleteInput label="Customer" validate={false} />
    </MyReferenceInput>,
    <DateInput source="startDate_gte" label="Start Date" fullWidth />,
    <DateInput source="endDate_lte" label="End Date" fullWidth />,
    <MyReferenceInput
      alwaysOn
      label="Status"
      source="workOrderStatusTypeId"
      reference="workorderstatustypes"
    >
      <MyAutoCompleteInput label="Status" validate={false} />
    </MyReferenceInput>,
    <TextInput source="workOrderNumber" />,
    <TextInput source="customerServiceOrder" />,
    <MyReferenceInput
      label="Work Order Type"
      source="workOrderTypeId"
      reference="ibeworkordertypes"
    >
      <MyAutoCompleteInput label="Work Order Type" validate={false} />
    </MyReferenceInput>,
    <MyReferenceInput
      label="Project"
      source="projectId"
      reference="projects"
      {...(!isSuper
        ? {
            filter: {
              internalBusinessEntityId: InternalBusinessEntityId,
            },
          }
        : {})}
    >
      <MyAutoCompleteInput label="Project" validate={false} />
    </MyReferenceInput>,
  ];
};

export const AddressFilters = [
  <TextInput source="name" />,
  <MyReferenceInput
    label={"Address Type"}
    source="addressTypeId"
    reference="addresstypes"
  >
    <MyAutoCompleteInput label="Address Type" validate={false} />
  </MyReferenceInput>,
];

export const EndUserFilters = (
  InternalBusinessEntityId: any,
  isSuper: boolean
) => {
  //returns an array from function, rather than as const variable used elsewhere in code
  return [
    <IncludeDisabled alwaysOn q="children" />,
    <TextInput source="name" />,
  ];
};

export const CustomerFilters = [
  <IncludeDisabled alwaysOn q="parent" />,
  <TextInput source="name" />,
  <TextInput source="accountNumber" />,
  <MyReferenceInput
    label="Customer Type"
    source="customerTypeId"
    reference="customertypes"
  >
    <MyAutoCompleteInput label="Customer Type" validate={false} />
  </MyReferenceInput>,
];

export const SupplierFilters = [
  <IncludeDisabled alwaysOn />,
  <TextInput source="name" />,
  <MyReferenceInput
    label="Supplier Type"
    source="supplierTypeId"
    reference="suppliertypes"
  >
    <MyAutoCompleteInput label="Supplier Type" validate={false} />
  </MyReferenceInput>,
];

export const PeopleFilters = [
  <IncludeDisabled alwaysOn />,
  <TextInput source="name" />,
  <TextInput source="email" />,
];
