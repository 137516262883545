
import SettingsIcon from '@mui/icons-material/Settings';
import { AppSettingList } from "./AppSetting";

const resource = {
  list: AppSettingList,
  icon: SettingsIcon,
};

export default resource;
