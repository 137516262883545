// in ./CustomResetViewsButton.tsx
import {
  useListContext,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
  Button,
  useUnselect,
} from "react-admin";

import { useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import apiConfig from "config/apiconfig.json";

export const BulkSubscribeAction = (props: any) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("posts");
  const { data, resource, selectedIds } = useListContext();

  const unselect = useUnselect(resource);
  let isLoading = false;
  const subscribeToWebsocket = async () => {
    var symbolList = "";

    var i = 0;
    var counter = 0;
    for (i = 0; i < data.length; i++) {
      if (selectedIds.includes(data[i].id)) {
        if (counter == 0) {
          symbolList = data[i].Symbol;
        } else {
          symbolList = symbolList + "," + data[i].Symbol;
        }
        counter += 1;
      }
    }

    isLoading = true;
    const subscribeResult = await fetch(
      apiConfig.api_url + "/subscribewebsocket?symbols=" + symbolList
    );

    const returnData = await subscribeResult.json();
    if (!subscribeResult.ok) {
      notify(`FAILURE while subscribing to symbols: ` + symbolList, {
        type: "error",
      });
    } else {
      notify(`Subscribing to symbols: ` + symbolList, { type: "success" });
    }

    unselect(selectedIds);
    isLoading = false;
  };

  return (
    <Button {...props} disabled={isLoading} onClick={subscribeToWebsocket}>
      <AddCircleOutlineIcon />
    </Button>
  );
};

export const BulkUnsubscribeAction = (props: any) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("posts");
  const { data, resource, selectedIds } = useListContext();

  const unselect = useUnselect(resource);
  let isLoading = false;
  const unsubscribeToWebsocket = async () => {
    var symbolList = "";

    var i = 0;
    var counter = 0;
    for (i = 0; i < data.length; i++) {
      if (selectedIds.includes(data[i].id)) {
        if (counter == 0) {
          symbolList = data[i].Symbol;
        } else {
          symbolList = symbolList + "," + data[i].Symbol;
        }
        counter += 1;
      }
    }

    isLoading = true;
    const subscribeResult = await fetch(
      apiConfig.api_url + "/unsubscribewebsocket?symbols=" + symbolList
    );

    const returnData = await subscribeResult.json();
    if (!subscribeResult.ok) {
      notify(`FAILURE while Unsubscribing to symbols: ` + symbolList, {
        type: "error",
      });
    } else {
      notify(`Unsubscribing to symbols: ` + symbolList, { type: "success" });
    }

    unselect(selectedIds);
    isLoading = false;
  };

  return (
    <Button {...props} disabled={isLoading} onClick={unsubscribeToWebsocket}>
      <AddCircleOutlineIcon />
    </Button>
  );
};
