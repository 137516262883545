import DescriptionIcon from "@material-ui/icons/Description";

import { CurrentStockPriceList } from "./CurrentStockPrice";

const resource = {
  list: CurrentStockPriceList,
  icon: DescriptionIcon,
};

export default resource;
