import {
  CreateDialogComponent,
  EditDialogComponent,
} from "components/forms/dialogs/ModalDialog";
import {
  EmptyDataForm,
  ListActions,
  MyCurrentPriceField,
} from "components/getForms/FormUtilities";
import { Fragment, memo } from "react";
import { useNotify } from "react-admin";
import {
  BooleanField,
  NumberField,
  List as RAList,
  TextField as RATextField,
  FunctionField,
  useRecordContext,
} from "react-admin";
import Button from "@mui/material/Button";
import { Box, Typography } from "@material-ui/core";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";

import { WeeklyStockWatchForm } from "../../getForms/GetForms";

import { MyDatagridConfigurable } from "components/getForms/FormUtilities";

import apiConfig from "config/apiconfig.json";
import React, { useState, useEffect } from "react";

import {
  BulkSubscribeAction,
  BulkUnsubscribeAction,
} from "./BulkSubscribeActions";

const PostListBulkActions = memo(({ ...props }) => <Fragment></Fragment>);
const PostBulkActionButtons = () => (
  <>
    <BulkSubscribeAction label="Subscribe" />
    <BulkUnsubscribeAction label="Unsubscribe" />
    {/* default bulk delete action */}
  </>
);

export const CurrentStockPriceList = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [wsAction, setwsAction] = useState("");
  const [isActionEnabled, setActionEnabled] = useState(false);
  const [isFirstLoad, SetIsFirstLoad] = useState(true);

  const [isSubscribed, setIsSubscribed] = useState(false);
  function handleIsSubscribedState(newValue: false) {
    setIsSubscribed(newValue);
  }

  // Run! Like go get some data from an API.

  const fetchData = async () => {
    const response = await fetch(
      apiConfig.api_url + "/websocketconnectionstatus"
    );
    const newData = await response.json();
    setIsOpen(newData);
  };

  useEffect(() => {
    let intervalId = setInterval(fetchData, 5000);
    return () => clearInterval(intervalId);
  }, []);

  if (isFirstLoad) {
    fetchData();
    SetIsFirstLoad(false);
  }
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAction: string
  ) => {
    setwsAction(newAction);
    if (
      newAction == "subscribe" ||
      newAction == "unsubscribe" ||
      newAction == "reset"
    ) {
      setActionEnabled(true);
    } else {
      setActionEnabled(false);
    }
  };

  // async format

  const notify = useNotify();
  const postRowSx = (record: any, index: BigInteger) => ({
    backgroundColor: record.CurrentPrice > 0 ? "#efe" : "white",
  });

  const openWebsocket = async () => {
    setDisabled(true);
    const res = await fetch(apiConfig.api_url + "/openwebsocket");
    const data = await res.json();
    notify(`Notification:` + data, { type: "success" });

    setDisabled(false);
    setIsOpen(true);
  };
  const subscribeToWebsocket = async () => {
    let symbols: any = document.getElementById("symbols") as HTMLElement;
    setActionEnabled(false);
    const res = await fetch(
      apiConfig.api_url + "/subscribewebsocket?symbols=" + symbols.value
    );
    const data = await res.json();
    let i = 0;
    setActionEnabled(true);
  };
  const UnsubscribeToWebsocket = async () => {
    let symbols: any = document.getElementById(
      "unSubscribeSymbols"
    ) as HTMLElement;
    setActionEnabled(false);
    const res = await fetch(
      apiConfig.api_url + "/unsubscribewebsocket?symbols=" + symbols.value
    );
    const data = await res.json();
    let i = 0;
    setActionEnabled(true);
  };
  const ResetSubscription = async () => {
    setActionEnabled(false);
    const res = await fetch(apiConfig.api_url + "/resetsubscriptions");
    const data = await res.json();
    let i = 0;
    setActionEnabled(true);
  };
  const closeWebsocket = async () => {
    setDisabled(true);
    const res = await fetch(apiConfig.api_url + "/closewebsocket");
    const data = await res.json();
    setDisabled(false);
    setIsOpen(false);
  };
  const subscribeWebsocket = async () => {
    const res = await fetch(apiConfig.api_url + "/subscribewebsocket");
    const data = await res.json();
  };

  const MyStockPrice = (props: any) => {
    let id = props.id;
    var price;
    const record = useRecordContext(props);

    const [stockPrice, setStockPrice] = useState(0);

    useEffect(() => {
      (async (rec) => {
        let content = "";
        try {
          if (isActionEnabled) {
            const response = await fetch(
              apiConfig.api_url + "/getsymbolprice?symbol=" + rec.Symbol
            );
            const res = await response.json();
            setStockPrice(res);
          }
        } catch (error) {
          console.warn("Error returning stock price:", error);
        }
      })(record);
    }, [record, setStockPrice]);

    const getSymbolPrice = async () => {
      const res = await fetch(apiConfig.api_url + "/getsymbolprice?symbol=");
      const data = await res.json();
      price = data;
      let i = 0;
      i = i + 1;
    };

    return <span>{stockPrice}</span>;
  };

  return (
    <>
      <Box
        sx={{ width: "100%", paddingTop: "0" }}
        display={{ xs: "block", sm: "block" }}
        mb={{ xs: "0.5em" }}
      >
        <h1>Websocket connections</h1>
        <Button
          sx={{ margin: "1em" }}
          size="medium"
          variant="contained"
          color="success"
          onClick={openWebsocket}
          disabled={isDisabled || isOpen}
        >
          Open Web Socket
        </Button>

        <Button
          sx={{ margin: "1em" }}
          size="medium"
          variant="contained"
          color="error"
          onClick={closeWebsocket}
          disabled={isDisabled || !isOpen}
        >
          Close Web Socket
        </Button>
      </Box>

      {isOpen && (
        <RAList
          queryOptions={{ refetchInterval: 5000 }}
          empty={<EmptyDataForm title="Current Stock Prices" />}
          resource="weeklystockwatches"
          title="Current Stock Prices"
          actions={
            <ListActions
              createButtonLabel="New Stock Watch"
              showFilter={false}
              showCreate={false}
              showExport={false}
            />
          }
          filter={{
            LocalUserID: PersonId,
          }}
          sort={{ field: "Symbol", order: "ASC" }}
          perPage={25}
        >
          <MyDatagridConfigurable
            omit={["disabled"]}
            bulkActionButtons={<PostBulkActionButtons />}
            rowSx={postRowSx}
          >
            <RATextField source="Symbol" />
            <MyCurrentPriceField source="CurrentPrice" label="Current Price" />

            <BooleanField label="Stalled" source="HasStoppedTrading" />
            <NumberField source="StrikePrice" />
            <BooleanField source="PaperTradeOnly" />
          </MyDatagridConfigurable>
        </RAList>
      )}

      <CreateDialogComponent maxWidth={false} form={WeeklyStockWatchForm} />
      <EditDialogComponent maxWidth={false} form={WeeklyStockWatchForm} />
    </>
  );
};
