import LocalOfferIcon from "@mui/icons-material/LocalOfferOutlined";
import { Card, CardContent } from "@mui/material";
import inflection from "inflection";
import {
  FilterList,
  FilterListItem,
  SelectInput,
  useGetList,
} from "react-admin";

import {
  Contract,
  Customer,
  InternalBusinessEntity,
  Partner,
  Project,
  Supplier,
  WorkOrder,
  ItemCategory,
} from "types";

import { useListFilterContext, useGetIdentity } from "react-admin";
import { Box, Switch, FormControlLabel } from "@mui/material";
import { hasRole, LoginRoleTypes } from "./permissions";
export const IBEListAside = () => {
  const { data } = useGetList<InternalBusinessEntity>("ibes", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "name", order: "ASC" },
  });

  return (
    <Card
      sx={{
        marginTop: "64px",
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Companies" icon={<LocalOfferIcon />}>
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={record.name}
                key={record.id}
                value={{ internalBusinessEntityId: record.id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const CustomerListAside = () => {
  const { data } = useGetList<Customer>("customers", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "name", order: "ASC" },
    filter: {
      ...(!hasRole(LoginRoleTypes.SUPER)
        ? { internalBusinessEntityId: InternalBusinessEntityId }
        : {}),
    },
  });

  return (
    <Card
      sx={{
        marginTop: "64px",
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Customers" icon={<LocalOfferIcon />}>
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={record.name}
                key={record.id}
                value={{ entityId: record.id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const WorkOrderForWOTasksListAside = () => {
  const { data } = useGetList<WorkOrder>("workorders", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "workOrderNumber", order: "ASC" },
    filter: {
      ...(!hasRole(LoginRoleTypes.SUPER)
        ? { internalBusinessEntityId: InternalBusinessEntityId }
        : {}),
    },
  });

  return (
    <Card
      sx={{
        marginTop: "64px",
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Work Orders" icon={<LocalOfferIcon />}>
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={inflection.humanize(record.workOrderNumber)}
                key={record.id}
                value={{ workOrderId: record.id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const ContractsForContractItemsAside = () => {
  const { data } = useGetList<Contract>("contracts", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "name", order: "ASC" },
    filter: {
      ...(!hasRole(LoginRoleTypes.SUPER)
        ? { internalBusinessEntityId: InternalBusinessEntityId }
        : {}),
    },
  });

  return (
    <Card
      sx={{
        marginTop: "64px",
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Contracts" icon={<LocalOfferIcon />}>
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={record.name}
                key={record.id}
                value={{ contractId: record.id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const ProjectsForWOListAside = () => {
  const { data } = useGetList<Project>("projects", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "name", order: "ASC" },
    filter: {
      ...(!hasRole(LoginRoleTypes.SUPER)
        ? { internalBusinessEntityId: InternalBusinessEntityId }
        : {}),
    },
  });

  return (
    <Card
      sx={{
        marginTop: "64px",
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Projects" icon={<LocalOfferIcon />}>
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={record.name}
                key={record.id}
                value={{ projectId: record.id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const CategoryParentsListAside = (props: any) => {
  let idField = props.idField;
  const { data } = useGetList<ItemCategory>("itemcategories", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "name", order: "ASC" },
    filter: {
      ...(!hasRole(LoginRoleTypes.SUPER)
        ? { internalBusinessEntityId: InternalBusinessEntityId, q: "parent" }
        : { q: "parent" }),
    },
  });

  return (
    <Card
      sx={{
        marginTop: "64px",
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Parent Categories" icon={<LocalOfferIcon />}>
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={record.name}
                key={record.id}
                value={{ [idField]: record.id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const CustomerParentsListAside = (props: any) => {
  let idField = props.idField;
  const { data } = useGetList<Customer>("customers", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "name", order: "ASC" },
    filter: {
      ...(!hasRole(LoginRoleTypes.SUPER)
        ? { internalBusinessEntityId: InternalBusinessEntityId, q: "parent" }
        : { q: "parent" }),
    },
  });

  return (
    <Card
      sx={{
        marginTop: "64px",
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Customers" icon={<LocalOfferIcon />}>
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={record.name}
                key={record.id}
                value={{ [idField]: record.id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const ContractsForProjectsListAside = () => {
  const { data } = useGetList<Contract>("contracts", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "name", order: "ASC" },
    filter: {
      ...(!hasRole(LoginRoleTypes.SUPER)
        ? { internalBusinessEntityId: InternalBusinessEntityId }
        : {}),
    },
  });

  return (
    <Card
      sx={{
        marginTop: "64px",
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Contracts" icon={<LocalOfferIcon />}>
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={record.name}
                key={record.id}
                value={{ contractId: record.id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const PartnerListAside = () => {
  const { data } = useGetList<Partner>("partners", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "name", order: "ASC" },
    filter: {
      ...(!hasRole(LoginRoleTypes.SUPER)
        ? { internalBusinessEntityId: InternalBusinessEntityId }
        : {}),
    },
  });

  return (
    <Card
      sx={{
        marginTop: "64px",
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="SubContractors" icon={<LocalOfferIcon />}>
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={record.name}
                key={record.id}
                value={{ entityId: record.id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const SupplierListAside = () => {
  const { data } = useGetList<Supplier>("suppliers", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "name", order: "ASC" },
    filter: {
      ...(!hasRole(LoginRoleTypes.SUPER)
        ? { internalBusinessEntityId: InternalBusinessEntityId }
        : {}),
    },
  });

  return (
    <Card
      sx={{
        marginTop: "64px",
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Suppliers" icon={<LocalOfferIcon />}>
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={record.name}
                key={record.id}
                value={{ entityId: record.id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const IBEAddressListAside = () => {
  const { data } = useGetList<InternalBusinessEntity>("ibes", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "name", order: "ASC" },
  });

  return (
    <Card
      sx={{
        marginTop: "64px",
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Companies" icon={<LocalOfferIcon />}>
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={record.name}
                key={record.id}
                value={{ entityId: record.id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const IBEPeopleListAside = () => {
  const { data } = useGetList<InternalBusinessEntity>("ibes", {
    pagination: { page: 1, perPage: 100 },
    sort: { field: "name", order: "ASC" },
  });

  return (
    <Card
      sx={{
        marginTop: "64px",
        display: { xs: "none", md: "block" },
        order: -1,
        width: "15em",
        mr: 2,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterList label="Companies" icon={<LocalOfferIcon />}>
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={record.name}
                key={record.id}
                value={{ entityId: record.id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export const SelectEntityTypes = () => {
  return (
    <SelectInput
      source="entityType"
      choices={[
        { id: "programming", name: "Programming" },
        { id: "lifestyle", name: "Lifestyle" },
        { id: "photography", name: "Photography" },
      ]}
    />
  );
};

//currently NOT able to use standard q with 2 parameters, so CREATING it on the fly

export const IncludeDisabled = (props: any) => {
  const { filterValues, displayedFilters, setFilters } = useListFilterContext();
  const { identity } = useGetIdentity();
  let qFilter = IncludeDisabled.defaultProps.q;

  let qFilterProps = props.q;

  if (qFilterProps !== undefined || qFilterProps !== "") {
    //reset qFilter
    qFilter = qFilterProps;
  }

  if (qFilter === undefined || qFilter === "") {
    //do nothing, NO filters passed in
  } else {
    //if current filterValues undefined, set to incoming qFilter
    if (typeof filterValues.q === "undefined") {
      const newFilterValues = { ...filterValues };
      newFilterValues.q = qFilter;
      setFilters(newFilterValues, displayedFilters);
    }
  }

  const handleChange = () => {
    //is checked?
    let isChecked =
      typeof filterValues.q !== "undefined" && filterValues.q !== qFilter;

    let newQFilter = "";
    if (qFilter === undefined || qFilter === "") {
      //do nothing
    } else {
      newQFilter = qFilter + ",";
    }

    const newFilterValues = { ...filterValues };
    if (isChecked) {
      //is CURRENTLY checked... so will reset
      isChecked = false;
      delete newFilterValues.q;
      newFilterValues.q = qFilter;
    } else {
      //is NOT currently checked, so set and check
      isChecked = true;
      newFilterValues.q = newQFilter + "includeDisabled";
    }
    setFilters(newFilterValues, displayedFilters);
  };
  return (
    <Box sx={{ marginBottom: 1, marginLeft: 1 }}>
      <FormControlLabel
        control={
          <Switch
            checked={
              typeof filterValues.q !== "undefined" &&
              filterValues.q !== qFilter
            }
            onChange={handleChange}
            color="primary"
            name="isDisabled"
            id="isDisabled"
          />
        }
        label="Include Disabled"
      />
    </Box>
  );
};

//not sure if this is needed?
IncludeDisabled.defaultProps = { q: "" };
