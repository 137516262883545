export enum SystemModels {
  InventoryItem = 1,
  MasterItem = 2,
  WorkOrder = 3,
}

export enum PermissionType {
  NONE = 1,
  RO = 2,
  RW = 3,
  FULL = 4,
}

export enum LoginRoleTypes {
  SUPER = 1,
  TRADER = 2,
  OFFICE_MANAGER = 7,
  INVENTORY_CONTROL_SPECIALIST = 8,
  FIELD_TECHNICIAN = 9,
  BUSINESS_ADMINISTRATOR = 10,
  FIELD_SUPPORT_SERVICES = 12,
  WAREHOUSE_MANAGER = 13,
}

export interface interfaceUserRole {
  id: number;
  name: string;
  description: string;
}

export const hasRole = (userRole: LoginRoleTypes) => {
  let hasThisRole = false;

  //Search array of objects
  //find in array of objects
  let searchIndex = -1; //NOT found

  searchIndex = UserRoles.findIndex(
    (UserRole: interfaceUserRole) => UserRole.id === Number(userRole)
  );

  if (searchIndex >= 0) {
    hasThisRole = true;
  }

  return hasThisRole;
};

export const isSuperRole = () => {
  let hasThisRole = false;

  if (hasRole(LoginRoleTypes.SUPER)) {
    hasThisRole = true;
  }

  return hasThisRole;
};

export const isAdministrativeRole = () => {
  let hasThisRole = false;

  if (
    hasRole(LoginRoleTypes.SUPER) ||
    hasRole(LoginRoleTypes.BUSINESS_ADMINISTRATOR)
  ) {
    hasThisRole = true;
  }

  return hasThisRole;
};

export const isFieldTechnicianRole = () => {
  let hasThisRole = false;

  if (hasRole(LoginRoleTypes.FIELD_TECHNICIAN)) {
    hasThisRole = true;
  }

  return hasThisRole;
};

export const isWarehouseManagerRole = () => {
  let hasThisRole = false;

  if (hasRole(LoginRoleTypes.WAREHOUSE_MANAGER)) {
    hasThisRole = true;
  }

  return hasThisRole;
};

export const isFieldSupportServicesRole = () => {
  let hasThisRole = false;

  if (hasRole(LoginRoleTypes.FIELD_SUPPORT_SERVICES)) {
    hasThisRole = true;
  }

  return hasThisRole;
};

export const getUserRoles = () => {
  let hasThisRole = false;
  let allUserRoles = "";

  let i = 0;

  UserRoles.forEach((Role: any) => {
    if (allUserRoles.length > 0) {
      allUserRoles = allUserRoles + ", ";
    }
    allUserRoles = allUserRoles + Role.name;
  });

  return allUserRoles;
};

export enum AllMenuItems {
  StockWatches = 8,
  Administration = 9,
  Users = 10,
  Roles = 11,
  MenuItems = 12,
  LocalUsers = 13,
  TradingPlatforms = 14,
  WeeklyStockWatches = 14,
  ConnectToTDAmeritrade = 15,
}

let MenuItems = [
  {
    id: AllMenuItems.Administration,
    name: "Dashboard",
    relativeOrder: 1,
    parentId: null,
  },
  {
    id: AllMenuItems.Roles,
    name: "Company",
    relativeOrder: 2,
    parentId: AllMenuItems.Administration,
  },
];

let MenuItemRoles = [
  {
    menuId: 9,
    roleId: 1,
  },
  {
    menuId: 10,
    roleId: 1,
  },
  {
    menuId: 11,
    roleId: 1,
  },
  {
    menuId: 12,
    roleId: 1,
  },
  {
    menuId: 13,
    roleId: 1,
  },
  {
    menuId: 14,
    roleId: 1,
  },
  {
    menuId: 14,
    roleId: 2,
  },
];

export const hasMenu = (menuItemId: number) => {
  let hasThisMenuPermission = false;
  let rolesArray = [];
  let menuItemRolesArray = [];
  let searchIndex = -1;

  UserRoles.forEach((UserRole: interfaceUserRole) => {
    rolesArray = UserRoles.filter((UserRole: interfaceUserRole) => {
      //perform a findIndex within loop, until it's true
      //then... you know you have the permission, so stop
      if (!hasThisMenuPermission) {
        searchIndex = MenuItemRoles.findIndex(
          (MenuItemRole) =>
            MenuItemRole.menuId === menuItemId &&
            MenuItemRole.roleId === UserRole.id
        );

        if (searchIndex >= 0) {
          hasThisMenuPermission = true;
        }
      }
    });
  });

  return hasThisMenuPermission;
};
