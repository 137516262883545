import DescriptionIcon from "@material-ui/icons/Description";

import { WeeklyStockWatchList } from "./WeeklyStockWatch";

const resource = {
  list: WeeklyStockWatchList,
  icon: DescriptionIcon,
};

export default resource;
