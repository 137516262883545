import { EditDialog } from "@react-admin/ra-form-layout";

import {} from "components/getForms/GetForms";

function EditDialogComponent(props: any) {
  let maxWidth: any;

  maxWidth = "md";

  if (props.maxWidth !== undefined) {
    maxWidth = props.maxWidth;
  }
  if (props.name === null) {
    return (
      <EditDialog fullWidth maxWidth={maxWidth}>
        <props.form />
      </EditDialog>
    );
  } else {
    return (
      <EditDialog fullWidth maxWidth={maxWidth} title={" "}>
        <props.form entityType={props.entityType} />
      </EditDialog>
    );
  }
}

export default EditDialogComponent;
