import {
  CreateDialogComponent,
  EditDialogComponent,
} from "components/forms/dialogs/ModalDialog";
import { EmptyDataForm, ListActions } from "components/getForms/FormUtilities";
import { BooleanField, List as RAList, TextField } from "react-admin";
import { RoleForm } from "../../getForms/GetForms";

import { MyDatagridConfigurable } from "components/getForms/FormUtilities";

export const RoleList = (props: any) => {
  return (
    <div>
      <RAList
        empty={<EmptyDataForm title="Roles" />}
        resource="roles"
        title="Roles"
        actions={
          <ListActions
            createButtonLabel="New Role"
            showFilter={false}
            showCreate={true}
            showExport={true}
          />
        }
        sort={{ field: "name", order: "ASC" }}
        perPage={25}
      >
        <MyDatagridConfigurable rowClick="edit" omit={["disabled"]}>
          <TextField source="name" />
          <TextField source="description" />
        </MyDatagridConfigurable>
      </RAList>

      <CreateDialogComponent maxWidth={false} form={RoleForm} />
      <EditDialogComponent maxWidth={false} form={RoleForm} />
    </div>
  );
};
