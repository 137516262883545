import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { Box, Chip, Typography } from "@mui/material";
import { LoginRoleTypes, hasRole } from "components/forms/common/permissions";
import { InventoryTransactionModelIds } from "components/globals/constants";
import { pluralize } from "inflection";
import * as React from "react";
import { useEffect, useState } from "react";

import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  CreateButton,
  DatagridConfigurable,
  ExportButton,
  FilterButton,
  InputProps,
  ListButton,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  SelectColumnsButton,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  required,
  useDataProvider,
  useGetOne,
  useRecordContext,
  useRedirect,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { useStyles } from "./GetForms";
export const UseStyles = makeStyles({
  loading: {
    height: "100vh!important" as any,
    marginTop: "-3px!important" as any,
  },
  noDialogStyling: {},
  title: {
    fontWeight: 800,
    textAlign: "left",
  },
  red: {
    color: "#ff0000",
  },
  logo: {
    height: "50px",
  },
  spacer: {
    flex: 1,
  },
  fullWidth: {
    width: "100%",
    padding: "25px 0",
  },
  isHidden: {
    visibility: "hidden",
    display: "none!important" as any,
  },
  marginLeftAuto: {
    marginLeft: "auto!important" as any,
  },
  autoComplete: {
    minWidth: "225px",
    padding: "2px inherit",
    zIndex: 4000,
  },
  minWidthAuto: {
    minWidth: "auto!important" as any,
    marginLeft: "0!important" as any,
    marginRight: "0!important" as any,
  },
  minWidth200: {
    minWidth: "200px",
  },
  minWidth200Important: {
    minWidth: "200px!important" as any,
  },
  minWidth0: {
    minWidth: 0,
  },
  width200: {
    width: "200px",
  },
  alignLeft: {
    textAlign: "left",
  },
  alignRight: {
    textAlign: "right",
  },
  numberFormat: {
    fontSize: "1rem!important" as any,
    fontWeight: "bold!important" as any,
  },
});

const QuickFilter = ({ label }: InputProps) => {
  return <Chip sx={{ mb: 1 }} label={label} />;
};

const postFilters = [
  <TextInput label="Search Customers" source="q" alwaysOn />,
  <QuickFilter source="name" label="name" />,
];

export const ListActions = (props: any) => {
  const classes = UseStyles();

  let showCreate = false;
  let showExport = false;
  let showFilter = false;
  let showSelectColumnsButton = true;
  let createButtonLabel = "Create";

  if (
    props.showSelectColumnsButton !== undefined &&
    props.showSelectColumnsButton !== ""
  ) {
    showSelectColumnsButton = props.showSelectColumnsButton;
  }
  if (props.createButtonLabel !== undefined && props.createButtonLabel !== "") {
    createButtonLabel = props.createButtonLabel;
  }
  if (props.showFilter !== undefined && props.showFilter !== "") {
    showFilter = props.showFilter;
  }
  if (props.showCreate !== undefined && props.showCreate !== "") {
    showCreate = props.showCreate;
  }
  if (props.showExport !== undefined && props.showExport !== "") {
    showExport = props.showExport;
  }
  return (
    <TopToolbar>
      {showSelectColumnsButton && <SelectColumnsButton />}
      {showFilter && <FilterButton />}
      {showCreate && (
        <CreateButton
          variant="contained"
          label={createButtonLabel}
          sx={{ marginLeft: 2 }}
        />
      )}
      {showExport && <ExportButton />}
    </TopToolbar>
  );
};
const ListActionsLimited = () => {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  );
};

const requiredValidate = [required()];

const PostEditActions = () => (
  <TopToolbar>
    <ListButton label="Cancel" icon={<ChevronLeft />} />
  </TopToolbar>
);

export const DisabledField = (props: any) => {
  const record = useRecordContext();
  const fieldType = props.fieldType;

  if (fieldType === "BooleanField") {
    return <BooleanField label="Disabled" source="disabled" />;
  }

  return <BooleanInput label="Disabled" source="disabled" />;
};

export const EmptyDataForm = (props: any) => {
  const classes = UseStyles();
  const entry = pluralize(props.title);

  var label = "create " + props.title;
  let showCreate = true;

  if (props.showCreate !== undefined) {
    showCreate = props.showCreate;
  }

  return (
    <div className={classes.fullWidth}>
      <Box textAlign="center">
        <Typography variant="h4" paragraph>
          No Current {entry}
        </Typography>

        {showCreate && (
          <>
            <Typography variant="body1">Create</Typography>
            <CreateButton label={label} />
          </>
        )}
      </Box>
    </div>
  );
};

export const SaveOnlyToolbar = (props: any) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

export const MyTextFieldWithID = (props: any) => {
  const record = useRecordContext(props);
  let newId = "";
  let id = props.id;
  let idNameField = props.idNameField;

  newId = id + "-" + record[idNameField];

  return <TextField {...props} id={newId} />;
};

export const MyDatagridConfigurable = (props: any) => {
  //HIDE checkboxes bulkActionButtons={false}
  return (
    <DatagridConfigurable {...props}>{props.children}</DatagridConfigurable>
  );
};

export const MyDateField = (props: any) => {
  const record = useRecordContext();
  const source = props.source;
  const value = record[source];
  let returnValue = "";

  //let todayString = format(value, "MM/dd/Y - hh:mm a");

  //returnValue = todayString;

  return <span>{value}</span>;
};

export const MyReferenceInput = (props: any) => {
  const record = useRecordContext(props);
  const [filter, setFilter] = useState(props.filter);
  const formContext = useFormContext();
  let dependencyFilterField = "";
  let referenceSourceField = "";
  let otherDependencyFilterField = "";
  let otherReferenceSourceField = "";
  let propsSort = {};

  if (
    props.dependencyFilterField !== undefined &&
    props.dependencyFilterField !== ""
  ) {
    dependencyFilterField = props.dependencyFilterField;
  }
  if (
    props.referenceSourceField !== undefined &&
    props.referenceSourceField !== ""
  ) {
    referenceSourceField = props.referenceSourceField;
  }
  //additional dependecies/reference fields
  if (
    props.otherDependencyFilterField !== undefined &&
    props.otherDependencyFilterField !== ""
  ) {
    otherDependencyFilterField = props.otherDependencyFilterField;
  }
  if (
    props.otherReferenceSourceField !== undefined &&
    props.otherReferenceSourceField !== ""
  ) {
    otherReferenceSourceField = props.otherReferenceSourceField;
  }

  //Default sort is "name"
  //if sort prop passed in, use it
  //if NOT... see if there is a child prop optionText being passed in
  //as this would be the value to use to sort on
  //

  propsSort = { field: "name", order: "ASC" };
  if (props.sort !== undefined) {
    propsSort = props.sort;
  } else {
    let childProps = React.Children.only(props.children);
    if (childProps !== undefined) {
      if (childProps.props.optionText !== undefined) {
        propsSort = { field: childProps.props.optionText, order: "ASC" };
      }
    }
  }

  return (
    <ReferenceInput {...props} filter={filter} sort={propsSort} perPage={999}>
      {props.children}
    </ReferenceInput>
  );
};

export const MyReferenceManyField = (props: any) => {
  const record = useRecordContext(props);
  const [filter, setFilter] = useState(props.filter);
  const formContext = useFormContext();
  let perPageProp = 999;
  if (props.perPage !== undefined && props.perPage !== "") {
    perPageProp = props.perPage;
  }

  return (
    <ReferenceManyField {...props} perPage={perPageProp}>
      {props.children}
    </ReferenceManyField>
  );
};

export const DependentReferenceArrayInput = (props: any) => {
  const record = useRecordContext(props);
  const [filter, setFilter] = useState(props.filter);
  const formContext = useFormContext();
  let dependencyFilterField = "";
  let referenceSourceField = "";
  let otherDependencyFilterField = "";
  let otherReferenceSourceField = "";

  if (
    props.dependencyFilterField !== undefined &&
    props.dependencyFilterField !== ""
  ) {
    dependencyFilterField = props.dependencyFilterField;
  }
  if (
    props.referenceSourceField !== undefined &&
    props.referenceSourceField !== ""
  ) {
    referenceSourceField = props.referenceSourceField;
  }
  //additional dependecies/reference fields
  if (
    props.otherDependencyFilterField !== undefined &&
    props.otherDependencyFilterField !== ""
  ) {
    otherDependencyFilterField = props.otherDependencyFilterField;
  }
  if (
    props.otherReferenceSourceField !== undefined &&
    props.otherReferenceSourceField !== ""
  ) {
    otherReferenceSourceField = props.otherReferenceSourceField;
  }

  useEffect(() => {
    const DependentReferenceArrayInput = formContext.watch(
      (value, { name, type }) => {
        if (name === referenceSourceField) {
          if (
            value[referenceSourceField] !== null &&
            value[referenceSourceField] !== undefined
          ) {
            setFilter((oldFilter: any) => ({
              ...oldFilter,
              [dependencyFilterField]: value[referenceSourceField],
            }));
          } else {
            setFilter((oldFilter: any) => ({
              ...oldFilter,
              [dependencyFilterField]: "",
            }));
          }
          formContext.setValue(props.source, "", {
            shouldTouch: true,
            shouldDirty: true,
          });
        }

        if (name === otherReferenceSourceField) {
          if (
            value[otherReferenceSourceField] !== null &&
            value[otherReferenceSourceField] !== undefined
          ) {
            setFilter((oldFilter: any) => ({
              ...oldFilter,
              [otherDependencyFilterField]: value[otherReferenceSourceField],
            }));
          } else {
            setFilter((oldFilter: any) => ({
              ...oldFilter,
              [otherReferenceSourceField]: "",
            }));
          }
          formContext.setValue(props.source, "", {
            shouldTouch: true,
            shouldDirty: true,
          });
        }
      }
    );
    return () => DependentReferenceArrayInput.unsubscribe();
  });

  return (
    <ReferenceArrayInput {...props} filter={filter}>
      {props.children}
    </ReferenceArrayInput>
  );
};

export const DependentReferenceInput = (props: any) => {
  const record = useRecordContext(props);
  const [filter, setFilter] = useState(props.filter);
  const formContext = useFormContext();
  let dependencyFilterField = "";
  let referenceSourceField = "";
  let otherDependencyFilterField = "";
  let otherReferenceSourceField = "";
  //if present, then "array" for otherDependencyFilterType will be handled uniquely
  let otherDependencyFilterType = "";
  let propsSort = {};

  if (
    props.dependencyFilterField !== undefined &&
    props.dependencyFilterField !== ""
  ) {
    dependencyFilterField = props.dependencyFilterField;
  }
  if (
    props.referenceSourceField !== undefined &&
    props.referenceSourceField !== ""
  ) {
    referenceSourceField = props.referenceSourceField;
  }
  //additional dependecies/reference fields
  if (
    props.otherDependencyFilterField !== undefined &&
    props.otherDependencyFilterField !== ""
  ) {
    otherDependencyFilterField = props.otherDependencyFilterField;
  }
  if (
    props.otherReferenceSourceField !== undefined &&
    props.otherReferenceSourceField !== ""
  ) {
    otherReferenceSourceField = props.otherReferenceSourceField;
  }
  if (
    props.otherDependencyFilterType !== undefined &&
    props.otherDependencyFilterType !== ""
  ) {
    otherDependencyFilterType = props.otherDependencyFilterType;
  }
  //Default sort is "name"
  //if sort prop passed in, use it
  //if NOT... see if there is a child prop optionText being passed in
  //as this would be the value to use to sort on
  //

  propsSort = { field: "name", order: "ASC" };
  if (props.sort !== undefined) {
    propsSort = props.sort;
  } else {
    let childProps = React.Children.only(props.children);
    if (childProps !== undefined) {
      if (childProps.props.optionText !== undefined) {
        propsSort = { field: childProps.props.optionText, order: "ASC" };
      }
    }
  }

  useEffect(() => {
    const DependentReferenceInput = formContext.watch(
      (value, { name, type }) => {
        if (name === referenceSourceField) {
          if (
            value[referenceSourceField] !== null &&
            value[referenceSourceField] !== undefined
          ) {
            setFilter((oldFilter: any) => ({
              ...oldFilter,
              [dependencyFilterField]: value[referenceSourceField],
            }));
          } else {
            setFilter((oldFilter: any) => ({
              ...oldFilter,
              [dependencyFilterField]: "",
            }));
          }
          formContext.setValue(props.source, null, {
            shouldTouch: true,
            shouldDirty: true,
          });
        }

        if (name === otherReferenceSourceField) {
          if (
            value[otherReferenceSourceField] !== null &&
            value[otherReferenceSourceField] !== undefined
          ) {
            if (otherDependencyFilterType === "array") {
              if (value[otherReferenceSourceField].length === 0) {
                //setFilter(props.filter);
              } else {
                let otherDependencyFilterFieldValue =
                  value[otherReferenceSourceField].split();
                setFilter((oldFilter: any) => ({
                  ...oldFilter,
                  [otherDependencyFilterField]: otherDependencyFilterFieldValue,
                }));
              }
            } else {
              setFilter((oldFilter: any) => ({
                ...oldFilter,
                [otherDependencyFilterField]: value[otherReferenceSourceField],
              }));
            }
          } else {
            if (otherDependencyFilterType === "array") {
              let currentFilter = filter;

              //if the value[otherReferenceSourceField] is empty
              //then delete it entirely from the filter
              //remove from the state variable, and then set it accordingly
              delete currentFilter[otherDependencyFilterField];
              setFilter(currentFilter);
            } else {
              setFilter((oldFilter: any) => ({
                ...oldFilter,
                [otherReferenceSourceField]: "",
              }));
            }
          }
          formContext.setValue(props.source, null, {
            shouldTouch: true,
            shouldDirty: true,
          });
        }
      }
    );
    return () => DependentReferenceInput.unsubscribe();
  });

  return (
    <ReferenceInput {...props} filter={filter} sort={propsSort} perPage={999}>
      {props.children}
    </ReferenceInput>
  );
};

export const BooleanDependentReferenceInput = (props: any) => {
  const formContext = useFormContext();
  //the filter prop MUST be used on the outgoing control
  //otherwise, it just isn't updated
  const [filter, setFilter] = useState(props.filter);
  let propsSort = {};

  if (props.sort !== undefined) {
    propsSort = props.sort;
  } else {
    propsSort = { field: "name", order: "ASC" };
  }

  useEffect(() => {
    const BooleanDependentReferenceInput = formContext.watch(
      (value, { name, type }) => {
        if (name === props.booleanField) {
          if (value[props.booleanField] !== undefined) {
            if (props.useBooleanValue) {
              //props.filter[props.dependencyField] = value[props.booleanField];
              setFilter((oldFilter: any) => ({
                ...oldFilter,
                [props.dependencyField]: value[props.booleanField],
              }));
            } else {
              if (value[props.booleanField]) {
                //props.filter[props.dependencyField] = props.dependencyIdValue;
                setFilter((oldFilter: any) => ({
                  ...oldFilter,
                  [props.dependencyField]: props.dependencyIdValue,
                }));
              } else {
                let currentFilter = filter;
                delete currentFilter[props.dependencyField];
                setFilter(currentFilter);
              }
            }

            formContext.setValue(props.source, "", {
              shouldTouch: true,
              shouldDirty: true,
            });
            formContext.setValue("serialNumber", "", {
              shouldTouch: true,
              shouldDirty: true,
            });
          }
        }
      }
    );
    return () => BooleanDependentReferenceInput.unsubscribe();
  });

  //the filter prop MUST be used on the outgoing control
  //otherwise, it just isn't updated
  return (
    <ReferenceInput {...props} filter={filter} sort={propsSort} perPage={999}>
      {props.children}
    </ReferenceInput>
  );
};

export const EntityTableTextField = (props: any) => {
  const record = useRecordContext(props);
  //const value = get(record, source);
  let entityTable = "";
  let referenceSourceField = "";
  let fieldType = props.fieldType;

  if (fieldType === "AutoComplete") {
    return <MyAutoCompleteInput {...props} />;
  }

  if (fieldType === "PhoneLink") {
    let phoneNumber = record.phone;
    return <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>;
  }

  return <TextField {...props} />;
};

export const InventoryEntityTableReferenceField = (props: any) => {
  const record = useRecordContext(props);
  let entityTable = "";
  let showPending = false;
  let reference = "inventorytransactionmodels";
  let source = props.source;

  let inventoryTransactionModelId = 1;

  if (props.showPending != undefined && props.showPending !== "") {
    showPending = props.showPending;
  }

  if (!showPending) {
    inventoryTransactionModelId = record.inventoryTransactionModelId;
  } else {
    inventoryTransactionModelId = record.pendingInventoryTransactionModelId;
  }

  const { data, isLoading, error } = useGetOne(reference, {
    id: inventoryTransactionModelId,
  });

  if (isLoading) return <p>...</p>;

  if (inventoryTransactionModelId === undefined) {
    return <label>Not Defined</label>;
  }

  entityTable = pluralize(data.name).toLowerCase();

  let referenceFieldSource = "name";
  if (showPending) {
    if (
      Number(record.pendingInventoryTransactionModelId) ===
      InventoryTransactionModelIds.WorkOrder
    ) {
      referenceFieldSource = "workOrderNumber";
    } else {
      referenceFieldSource = "name";
    }
  } else {
    if (
      Number(record.inventoryTransactionModelId) ===
      InventoryTransactionModelIds.WorkOrder
    ) {
      referenceFieldSource = "workOrderNumber";
    } else {
      referenceFieldSource = "name";
    }
  }

  if (props.fieldType === "ReferenceInput") {
    return (
      <MyReferenceInput
        source={source}
        reference={entityTable}
        label={props.label}
      >
        {props.children}
      </MyReferenceInput>
    );
  }

  return (
    <ReferenceField label={props.label} source={source} reference={entityTable}>
      <EntityTableTextField source={referenceFieldSource} test={true} />
    </ReferenceField>
  );
};

export const PersonTableReferenceField = (props: any) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext(props);
  //const value = get(record, source);
  let entityTable = "";
  let referenceSourceField = "";
  let fieldType = "";

  let inventoryTransactionModelId = 1;
  if (record !== undefined) {
    inventoryTransactionModelId = record.inventoryTransactionModelId;
  }
  const { data, isLoading, error } = useGetOne(props.referenceSourceTable, {
    id: inventoryTransactionModelId,
  });

  if (isLoading) return <p>...</p>;

  if (
    record.inventoryTransactionModelId === undefined ||
    record.inventoryTransactionModelId === "1"
  ) {
    return <label></label>;
  }

  entityTable = pluralize(data.name).toLowerCase();

  if (props.fieldType === "ReferenceInput") {
    return (
      <MyReferenceInput source="entityId" reference={entityTable} {...props}>
        {props.children}
      </MyReferenceInput>
    );
  }

  return (
    <ReferenceField
      label={props.label}
      source="entityId"
      reference={entityTable}
    >
      {props.children}
    </ReferenceField>
  );
};

export const DurationAsHoursAndMinutes = (props: any) => {
  const classes = useStyles();

  const record = useRecordContext();

  //5.5 breaks into
  //[5,5]
  //use array item 1 as hours
  //use "0." + "5" = 0.5 for minutes
  let duration = 0;
  let outputDurationHoursMinutes = "";
  let durationString = "";

  if (record[props.source] !== undefined) {
    duration = record[props.source];
  }
  durationString = duration.toString();

  let durationArray = durationString.split(".");

  if (durationArray.length === 1) {
    outputDurationHoursMinutes = durationArray[0] + " hours: 0 Minutes";
  } else {
    let fractionToMinutes = Number("0." + durationArray[1]);

    fractionToMinutes = fractionToMinutes * 60;

    //round up
    fractionToMinutes = Math.round(fractionToMinutes);
    outputDurationHoursMinutes =
      durationArray[0] + " hours: " + fractionToMinutes + " Minutes";
  }

  return <span>{outputDurationHoursMinutes}</span>;
};

export const MyCurrentPriceField = (props: any) => {
  const record = useRecordContext();
  const source = props.source;
  const value = record[source];
  let decimalPlaces = 4;
  let returnValue = "";

  if (props.decimalPlaces !== undefined) {
    decimalPlaces = props.decimalPlaces;
  }

  if (value === undefined) {
    returnValue = "N/A";
  } else {
    if (value == 0) {
      returnValue = "N/A";
    } else {
      var valueAsString = String(value.toFixed(decimalPlaces));
      var splitString = valueAsString.split(".");
      var leftOfDecimalAsString = splitString[0];
      leftOfDecimalAsString = leftOfDecimalAsString.replace(
        /(\d)(?=(\d{3})+(?!\d))/g,
        "$1,"
      );
      var rightOfDecimalAsString = splitString[1];
      returnValue = "$" + leftOfDecimalAsString + "." + rightOfDecimalAsString;
      //value.toFixed(decimalPlaces).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  }

  return <span>{returnValue}</span>;
};

export const BasicNumberField = (props: any) => {
  const record = useRecordContext();
  const source = props.source;
  const value = record[source];
  let decimalPlaces = 4;
  let returnValue = "";

  return <span>{String(value)}</span>;
};

export const MyJsonToString = (props: any) => {
  const record = useRecordContext();
  const source = props.source;
  const value = record[source];
  let returnValue = "";
  let myJSON = "";

  if (value == null) {
    return <span></span>;
    //myJSON = JSON.stringify(value);
    //const obj = JSON.parse(value);
  }

  /*
      <br />
      Created At:{value.CreatedAt}
      <br />
  */
  return (
    <span>
      Quantity:{value.Quantity}
      <br />
      Price:
      {"$" +
        value.AveragePrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
    </span>
  );
};

export const MyStatesReferenceInput = (props: any) => {
  const classes = useStyles();

  return (
    <MyReferenceInput
      source="stateId"
      reference="states"
      {...props}
      fullWidth
      sort={{ field: "name", order: "ASC" }}
    >
      {props.children}
    </MyReferenceInput>
  );
};

export const MyAutoCompleteInput = (props: any) => {
  const classes = useStyles();
  let optionTextProp = "name";
  let optionValueProp = "id";
  let myClassName = classes.autoComplete;
  let isDisabled = false;
  let defaultValue = "";
  let defaultCreate: any;
  let defaultCreateItemLabel: any;
  let onChangeProp: any;
  let validateProp = requiredValidate;

  type myProps = {
    validate: any;
    optionText: string;
    optionValue: string;
    label: string;
    className: string;
    disabled: boolean;
    defaultValue: string;
    create: any;
    createItemLabel: any;
    onChange: any;
  };

  if (props.validate !== undefined) {
    validateProp = props.validate;
  }
  if (props.onChange !== undefined) {
    onChangeProp = props.onChange;
  }

  if (props.create !== undefined) {
    defaultCreate = props.create;
  }

  if (props.createItemLabel !== undefined) {
    defaultCreateItemLabel = props.createItemLabel;
  }

  if (props.defaultValue !== undefined && props.defaultValue !== "") {
    defaultValue = props.defaultValue;
  }

  if (props.optionText !== undefined && props.optionText !== "") {
    optionTextProp = props.optionText;
  }
  if (props.optionValue !== undefined && props.optionValue !== "") {
    optionValueProp = props.optionValue;
  }

  if (props.disabled !== undefined && props.disabled !== "") {
    isDisabled = props.disabled;
  }

  let defaultProps: myProps = {
    validate: validateProp,
    optionText: optionTextProp,
    optionValue: optionValueProp,
    label: props.label,
    className: myClassName,
    disabled: isDisabled,
    defaultValue: defaultValue,
    create: defaultCreate,
    createItemLabel: defaultCreateItemLabel,
    onChange: onChangeProp,
  };

  if (!props.validate && props.validate !== undefined) {
    defaultProps.validate = undefined;
  }

  return (
    <AutocompleteInput
      {...defaultProps}
      matchSuggestion={(filterValue, suggestion) => {
        const title = `${suggestion[optionTextProp]}`;
        return title.toLowerCase().includes(filterValue.toLowerCase());
      }}
    />
  );
};

export const MyAutoCompleteInputWithActions = (props: any) => {
  const dataProvider = useDataProvider();
  const { setValue } = useFormContext();
  const classes = useStyles();
  const record = useRecordContext();
  let apiData: any = [];
  let optionTextProp = "name";
  let myClassName = classes.autoComplete;
  let isDisabled = false;
  let hasRelatedFields = false;
  let autofillRelatedFields: string[];
  let autofillReferenceField = "";
  let autofillClearField = "";
  type myProps = {
    validate: any;
    optionText: string;
    label: string;
    className: string;
    disabled: boolean;
  };

  if (props.autofillRelatedFields.length > 0) {
    hasRelatedFields = true;
    autofillRelatedFields = props.autofillRelatedFields;
  }

  if (
    props.autofillClearField !== undefined &&
    props.autofillClearField !== ""
  ) {
    autofillClearField = props.autofillClearField;
  }

  if (
    props.autofillReferenceField !== undefined &&
    props.autofillReferenceField !== ""
  ) {
    autofillReferenceField = props.autofillReferenceField;
  }

  if (props.optionText !== undefined && props.optionText !== "") {
    optionTextProp = props.optionText;
  }

  if (props.disabled !== undefined && props.disabled !== "") {
    isDisabled = props.disabled;
  }

  let defaultProps: myProps = {
    validate: requiredValidate,
    optionText: optionTextProp,
    label: props.label,
    className: myClassName,
    disabled: isDisabled,
  };

  if (!props.validate && props.validate !== undefined) {
    defaultProps.validate = undefined;
  }

  function clearFieldValues() {
    var i;
    for (i = 0; i < autofillRelatedFields.length; i++) {
      setValue(autofillRelatedFields[i], null, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }

  function handleChangeContractItem(id: any) {
    // Queries the API and then autofills the description and name of associated master item
    dataProvider
      .getOne(autofillReferenceField, { id: id })
      .then(({ data }) => {
        //Clear Field
        if (autofillClearField !== "") {
          setValue(autofillClearField, null, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
        apiData = data;
        var i;
        for (i = 0; i < autofillRelatedFields.length; i++) {
          setValue(
            autofillRelatedFields[i],
            apiData[autofillRelatedFields[i]],
            {
              shouldValidate: true,
              shouldDirty: true,
            }
          );
        }
      })
      .catch((error) => {
        console.log(error.message);
      });

    return null;
  }

  return (
    <AutocompleteInput
      onChange={(newValue: any | null) => {
        if (props.autofill === undefined) {
          return null;
        }
        if (newValue === null || newValue === undefined || newValue === "") {
          clearFieldValues();
        } else {
          handleChangeContractItem(newValue);
        }
      }}
      {...defaultProps}
      matchSuggestion={(filterValue, suggestion) => {
        const title = `${suggestion[optionTextProp]}`;
        return title.toLowerCase().includes(filterValue.toLowerCase());
      }}
    />
  );
};

export const MyURLField = (props: any) => {
  const record = useRecordContext();
  const source = props.source;
  const URL = record[source];
  const value = props.value;
  const onClickStopPropagation = (e: any) => {
    // Prevent further propagation
    e.stopPropagation();
  };

  if (URL === undefined || URL === "") {
    return null;
  }

  return (
    <a target={"_blank"} href={URL} onClick={onClickStopPropagation}>
      {value}
    </a>
  );
};

export const IBEIdReferenceField = () => {
  if (hasRole(LoginRoleTypes.SUPER)) {
    return (
      <ReferenceField
        label="Company"
        source="internalBusinessEntityId"
        reference="ibes"
      >
        <TextField source="name" />
      </ReferenceField>
    );
  } else {
    return <></>;
  }
};
export const IBEIdReferenceInput = () => {
  const classes = useStyles();
  if (hasRole(LoginRoleTypes.SUPER)) {
    return (
      <MyReferenceInput
        source="internalBusinessEntityId"
        reference="ibes"
        fullWidth
      >
        <MyAutoCompleteInput label="Company" validate={requiredValidate} />
      </MyReferenceInput>
    );
  } else {
    return (
      <TextInput
        className={classes.isHidden}
        source="internalBusinessEntityId"
        disabled
        defaultValue={InternalBusinessEntityId}
      />
    );
  }
};

export const EntityIdReferenceInput = (props: any) => {
  const classes = useStyles();
  let isDisabled = false;

  if (props.disabled !== undefined && props.disabled !== "") {
    isDisabled = props.disabled;
  }

  if (!hasRole(LoginRoleTypes.SUPER)) {
    return (
      <TextInput
        className={classes.isHidden}
        source="entityId"
        disabled
        defaultValue={InternalBusinessEntityId}
      />
    );
  } else {
    return (
      <MyReferenceInput
        source="entityId"
        reference="ibes"
        label="Company"
        disabled={isDisabled}
      >
        <MyAutoCompleteInput
          disabled={isDisabled}
          label="Company"
          validate={requiredValidate}
        />
      </MyReferenceInput>
    );
  }
};

export const MyNumberInput = (props: any) => {
  const formContext = useFormContext();

  const numberInputOnWheelPreventChange = (e: any) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    //function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return <NumberInput {...props} onWheel={numberInputOnWheelPreventChange} />;
};

export const MyButtonLinkField = (props: any) => {
  const classes = useStyles();
  const record = useRecordContext();

  const redirect = useRedirect();
  const handleClick = () => {
    redirect("/" + props.resource + "/" + record[props.source]);
  };

  let label = props.label;
  let url = "/" + props.resource + "/" + record[props.source];

  return (
    <Button
      className={classes.marginTop5px}
      variant="contained"
      color="primary"
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};
