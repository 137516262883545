import DescriptionIcon from "@material-ui/icons/Description";

import { TradingPlatformList } from "./TradingPlatform";

const resource = {
  list: TradingPlatformList,
  icon: DescriptionIcon,
};

export default resource;
