import { CreateDialog, EditDialog } from "@react-admin/ra-form-layout";

import {} from "components/getForms/GetForms";
import { hasRole, LoginRoleTypes } from "../common/permissions";

export function EditDialogComponent(props: any) {
  let nameProp = props.name;
  let entityTypeProp = props.entityType;
  let title = "";

  if (props.modalTitle === null) {
    title = props.modalTitle;
  }

  var Width: any;
  Width = "md";
  if (props.maxWidth !== null) {
    Width = props.maxWidth;
  }
  if (!hasRole(LoginRoleTypes.SUPER) && props.permission === "denied") {
    return null;
  } else {
    if (props.name === null) {
      return (
        <EditDialog mutationMode="optimistic" title={" "} fullWidth={true} maxWidth={Width}>
          <props.form entityType={entityTypeProp} name={nameProp} />
        </EditDialog>
      );
    } else {
      return (
        <EditDialog mutationMode="optimistic" title={" "} fullWidth={true} maxWidth={Width}>
          <props.form entityType={entityTypeProp} name={nameProp} />
        </EditDialog>
      );
    }
  }
}

export function CreateDialogComponent(props: any) {
  const nameProp = props.name;
  const entityTypeProp = props.entityType;
  var Width: any;
  Width = "md";
  if (props.maxWidth !== null) {
    Width = props.maxWidth;
  }
  if (!hasRole(LoginRoleTypes.SUPER) && props.permission === "denied") {
    return null;
  } else {
    if (props.name === null) {
      return (
        <CreateDialog fullWidth maxWidth={Width}>
          <props.form
            dialogType="Create"
            entityType={entityTypeProp}
            name={nameProp}
          />
        </CreateDialog>
      );
    } else {
      return (
        <CreateDialog fullWidth maxWidth={Width} title={" "}>
          <props.form
            dialogType="Create"
            entityType={entityTypeProp}
            name={nameProp}
          />
        </CreateDialog>
      );
    }
  }
}
