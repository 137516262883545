import DescriptionIcon from '@material-ui/icons/Description';

import { PermissionList } from './Permission';

const resource = {
    list: PermissionList,
    icon: DescriptionIcon,
};

export default resource;
