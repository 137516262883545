import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";
import { useTranslate } from "react-admin";
import apiConfig from "config/apiconfig.json";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, { useState, useEffect } from "react";

import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {
  InventoryPendingAcceptance,
  MyWorkorders,
} from "./DashboardTechnicianItems";

import useMediaQuery from "@mui/material/useMediaQuery";
import { hasRole, LoginRoleTypes } from "../common/permissions";
//        : `linear-gradient(to right, #8975fb 0%, #746be7 35%), linear-gradient(to bottom, #8975fb 0%, #6f4ceb 50%), #6f4ceb`,

const useStyles = makeStyles((theme) => ({
  basicLink: {
    color: "#ffffff",
  },
  root: {
    background: getColor(InternalBusinessEntityId),
    color: "#fff",
    padding: 20,
    marginTop: theme.spacing(2),
    marginBottom: "1em",
  },
  actions: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
      flexWrap: "wrap",
      "& a": {
        marginTop: "1em",
        marginLeft: "0!important",
        marginRight: "1em",
      },
    },
  },
}));

const getColor = (IBE: string) => {
  switch (IBE) {
    case "1":
      return "#1976d2";
    case "2":
      return "#1976d2";
    case "21":
      return "#1976d2";
    default:
      return "#1976d2";
  }
};

export const Dashboard = () => {
  const theme = useTheme();

  let isTechnician = false;
  const isXSmall = useMediaQuery(theme.breakpoints.down("sm"));

  if (hasRole(LoginRoleTypes.FIELD_TECHNICIAN)) {
    isTechnician = true;
  }

  const translate = useTranslate();
  const classes = useStyles();
  return (
    <>
      <Box
        sx={{ width: "100%", paddingTop: "2em" }}
        mb={{ xs: "0.5em" }}
        className={classes.root}
      >
        <Box
          sx={{ width: { xs: 1 } }}
          mr={{ xs: 0, md: "0.5em" }}
          mb={{ xs: 0, sm: 0 }}
        >
          <Box
            sx={{ width: { xs: 1 } }}
            mr={{ xs: 0, sm: "0.5em" }}
            mb={{ xs: 0, sm: 0 }}
          >
            <Typography variant="h5" component="h2" gutterBottom>
              Welcome to the Kovest Trading application Version 2.0
            </Typography>
          </Box>

          {!hasRole(LoginRoleTypes.FIELD_TECHNICIAN) && (
            <Box
              sx={{ width: { xs: 1 } }}
              mb={{ xs: "0.5em", sm: 0 }}
              textAlign="left"
            >
              <Typography variant="body1" component="p" gutterBottom>
                Getting Started
                <ul>
                  <li>
                    The long term goal of this application is to provide a
                    single application space, for managing all accounts and
                    connectivity to a trading platform, for each individual user
                    and all there stock watches on a given trading platform.
                  </li>
                  <li>More features will be rolling out</li>
                  <li>Along with some basic data administration.</li>
                </ul>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
