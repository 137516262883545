import { useKeycloak } from "@react-keycloak/web";
import jwt_decode from "jwt-decode";
import { KeycloakTokenParsed } from "keycloak-js";
import { AuthProvider, UserIdentity } from "react-admin";

export type PermissionsFunction = (decoded: KeycloakTokenParsed) => boolean;

const useAuthProvider = (onPermissions: PermissionsFunction): AuthProvider => {
  const { keycloak } = useKeycloak();
  return {
    login: () => {
      return keycloak.login();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      let currentToken = keycloak.authenticated && keycloak.token;
      //X_KovestTrading_Token = "";
      if (keycloak.token !== undefined) {
        X_KovestTrading_Token = keycloak.idToken;
      }

      return currentToken
        ? Promise.resolve()
        : Promise.reject("Failed to obtain access token.");
    },
    logout: () => {
      let i = 0;
      return keycloak.logout();
    },
    getIdentity: (): Promise<UserIdentity> => {
      if (keycloak.token) {
        const decoded = jwt_decode<KeycloakTokenParsed>(keycloak.token);
        const id = decoded.sub || "";
        const fullName = decoded.name;
        return Promise.resolve({ id, fullName });
      }
      return Promise.reject("Failed to get identity.");
    },
    getPermissions: () => {
      if (!keycloak.token) {
        return Promise.resolve(false);
      }
      const decoded = jwt_decode<KeycloakTokenParsed>(keycloak.token);
      return Promise.resolve(onPermissions(decoded));
    },
  };
};

export default useAuthProvider;
