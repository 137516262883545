import DescriptionIcon from "@material-ui/icons/Description";

import { RoleList } from "./Role";

const resource = {
  list: RoleList,
  icon: DescriptionIcon,
};

export default resource;
