import { UseStyles } from "components/getForms/FormUtilities";
import {
  Layout,
  LayoutProps,
  Loading,
  useGetList,
  useGetOne,
  useDataProvider,
} from "react-admin";
import AppBar from "./AppBar";
import { Menu, MyHorizontalMenu } from "./menu";
import { ContainerLayout, HorizontalMenu } from "@react-admin/ra-navigation";
import { interfaceUserRole } from "components/forms/common/permissions";

let personLoading = true;

const MyLayout = (props: any) => {
  const classes = UseStyles();
  const dataProvider = useDataProvider();
  let apiData: any;

  //globalThis.PreferredUsername = "joel.bratsch";

  // const { data, total, isLoading, error } = useGetList("localusers", {
  //   filter: { username: PreferredUsername },
  // });

  // dataProvider
  //   .getList("localusers", {
  //     filter: {
  //       username: PreferredUsername,
  //     },
  //     pagination: { page: 1, perPage: 100 },
  //     sort: { field: "id", order: "ASC" },
  //   })
  //   .then(({ data }) => {
  //     apiData = data;
  //   })
  //   .catch((error) => {
  //     console.log(error.message);
  //   });
  // const { data, isLoading, error } = useGetOne("localusers", {
  //   id: 2,
  // });

  // dataProvider
  //   .getList("localusers", {
  //     filter: {
  //       username: PreferredUsername,
  //     },
  //     pagination: { page: 1, perPage: 100 },
  //     sort: { field: "id", order: "ASC" },
  //   })
  //   .then(({ data }) => {
  //     apiData = data;
  //   })
  //   .catch((error) => {
  //     console.log(error.message);
  //   });

  // const { data, isLoading, error } = useGetOne("localusers", {
  //   id: 9,
  // });

  const { data, total, isLoading } = useGetList("localusers", {
    filter: { userName: PreferredUsername },
  });

  globalThis.MenuRolesSet = false;
  dataProvider
    .getList("menuitems", {
      filter: {},
      pagination: { page: 1, perPage: 100 },
      sort: { field: "id", order: "ASC" },
    })
    .then(({ data }) => {
      let menuItemApiData: any = data;

      let MenuItem: interfaceUserRole;
      if (typeof MenuItems === "undefined") {
        globalThis.MenuItems = [];

        localStorage.setItem("MenuItems", JSON.stringify([]));
      }

      let i = 0;
      for (i = 0; i < apiData[0].Roles.length; i++) {
        //UserRoles[Number(apiData[0].Roles[i].RoleType.id)] =
        // apiData[0].Roles[i].RoleType.name;
        MenuItem = {
          id: Number(apiData[0].Roles[i].id),
          name: apiData[0].Roles[i].Name,
          description: apiData[0].Roles[i].Description,
        };
        globalThis.MenuItems.push(MenuItem);
      }
      globalThis.MenuRolesSet = true;
    })
    .catch((error) => {
      console.log(error.message);
    });

  //Until Permissions are Set... just LOADING... screen
  globalThis.PermissionsSet = false;
  if (!PermissionsSet || !MenuRolesSet || personLoading) {
    if (isLoading) {
      let i = 0;
      return (
        <Loading
          className={classes.loading}
          loadingPrimary="Loading..."
          loadingSecondary=""
        />
      );
    }

    let i = 0;
    let apiData: any = data;

    globalThis.DisplayUserName = apiData[0].DisplayName;
    globalThis.PersonId = apiData[0].id;

    let UserRole: interfaceUserRole;
    if (typeof UserRoles === "undefined") {
      globalThis.UserRoles = [];

      localStorage.setItem("UserRoles", JSON.stringify([]));
    }
    for (i = 0; i < apiData[0].Roles.length; i++) {
      //UserRoles[Number(apiData[0].Roles[i].RoleType.id)] =
      // apiData[0].Roles[i].RoleType.name;
      UserRole = {
        id: Number(apiData[0].Roles[i].id),
        name: apiData[0].Roles[i].Name,
        description: apiData[0].Roles[i].Description,
      };
      globalThis.UserRoles.push(UserRole);
    }

    localStorage.setItem("UserRoles", JSON.stringify(UserRoles));

    personLoading = false;
    PermissionsSet = true;

    //let personId = apiData.id;
  }

  return <MySecondLayout {...props} appBar={AppBar} menu={Menu} />;
};

const MySecondLayout = (props: any) => {
  const classes = UseStyles();

  const { data, total, isLoading } = useGetList("appsettings", {});

  //Until Permissions are Set... just LOADING... screen
  if (typeof AppSettingsSet === "undefined") {
    globalThis.AppSettingsSet = false;
  }

  if (!AppSettingsSet) {
    if (isLoading) {
      let i = 0;
      return (
        <Loading
          className={classes.loading}
          loadingPrimary="Loading..."
          loadingSecondary=""
        />
      );
    }

    let i = 0;
    let apiData: any = data;

    for (i = 0; i < apiData.length; i++) {
      if (apiData[i].key === "INVENTORYTRANSFER_REQUIRE_ACCEPTANCE") {
        if (apiData[i].value === "true") {
          AppSettings.INVENTORYTRANSFER_REQUIRE_ACCEPTANCE = true;
        } else {
          AppSettings.INVENTORYTRANSFER_REQUIRE_ACCEPTANCE = false;
        }
      }
    }

    AppSettingsSet = true;

    //let personId = apiData.id;
  }

  if (typeof InternalBusinessEntityId === "undefined") {
    globalThis.InternalBusinessEntityId = "";
  }

  return <Layout {...props} appBar={AppBar} menu={Menu} />;

  //return <ContainerLayout {...props} appBar={AppBar} menu={MyHorizontalMenu} />;
};

export default (props: LayoutProps) => {
  return <MyLayout {...props} appBar={AppBar} menu={Menu} />;
};
