import LocalOfferIcon from "@mui/icons-material/LocalOfferOutlined";
import { Card, CardContent } from "@mui/material";
import inflection from "inflection";
import {
  FilterList,
  FilterListItem,
  SelectInput,
  useGetList,
} from "react-admin";

import {
  Contract,
  Customer,
  InternalBusinessEntity,
  Partner,
  Project,
  Supplier,
  WorkOrder,
  ItemCategory,
} from "types";

import { useListFilterContext, useGetIdentity } from "react-admin";
import { Box, Switch, FormControlLabel } from "@mui/material";
import { hasRole, LoginRoleTypes } from "./permissions";

export const ValidateNumberNonZero = (value: number) => {
  let isValid = false;

  if (value == null) {
    return "Required";
  }

  if (value <= 0) {
    return "Must be greater than 0";
  }

  return undefined;
};

export const ValidateNegative = (value: number) => {
  let isValid = false;

  if (value == null) {
    return "Required";
  }

  if (value >= 0) {
    return "Must be Negative";
  }

  return undefined;
};

export const ValidateCompareLowHigh = async (
  value: any,
  values: any,
  props: any
) => {
  let lowString: string = ""; //= document.getElementById(lowElement) as HTMLElement;
  let highString: string = ""; //= document.getElementById(highElement) as HTMLElement;

  let inValid = false;

  if (props.source == "CutoffPercentTier1High") {
    if (values.CutoffPercentTier2High >= values.CutoffPercentTier1High) {
      inValid = true;
      highString = "Tier 1";
      lowString = "Tier 2";
    }
  }
  if (props.source == "CutoffPercentTier2High") {
    if (values.CutoffPercentTier3High >= values.CutoffPercentTier2High) {
      inValid = true;
      highString = "Tier 2";
      lowString = "Tier 3";
    }
  }
  if (props.source == "CutoffPercentTier3High") {
    if (values.CutoffPercentTier4High >= values.CutoffPercentTier3High) {
      inValid = true;
      highString = "Tier 3";
      lowString = "Tier 4";
    }
  }
  if (props.source == "CutoffPercentTier4High") {
    if (values.CutoffPercent >= values.CutoffPercentTier4High) {
      inValid = true;
      highString = "Tier 4";
      lowString = "Hard Cutoff";
    }
  }
  if (inValid) {
    return highString + " must be > " + lowString;
  }

  return undefined;
};
